// RouteMap.js

import React, {useEffect, useMemo} from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {Card} from "@mui/material";
import useSummary from "../../lib/hooks/useSummary";

const customPhotoIcon = (id) => new L.Icon({
    iconUrl: `https://picsum.photos/id/${id}/40/40`,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40]
});

const pickup = { latitude: 37.7749, longitude: -122.4194, imageId: 10, label: "Pick-up Location" };
const dropoff = { latitude: 36.7783, longitude: -119.4179, imageId: 20, label: "Drop-off Location" };

// A component to automatically adjust the map view to fit all markers
const FitBoundsToMarkers = ({ markers }) => {
    const map = useMap();

    useEffect(() => {
        const bounds = L.latLngBounds(markers.map(marker => [marker.latitude, marker.longitude]));
        map.fitBounds(bounds);
    }, [markers, map]);

    return null;
};

const RouteMap = () => {
    const { data, isLoading, isRefetching, refetch } = useSummary()
    const pickupLocation = useMemo(() => data?.route_info.pickup.location, [data])
    const dropoffLocation = useMemo(() => data?.route_info.dropoff.location, [data])
    const checkinLocations = useMemo(() => data?.route_info.checkins || [], [data])
    //const allMarkers = [pickup, ...checkIns, dropoff];
    const allMarkers = [pickupLocation,
        ...checkinLocations.map(checkin => checkin.location), dropoffLocation];

    return (
        <Card>
        <MapContainer center={[37.0902, -95.7129]} zoom={13} style={{ height: '500px', width: '100%' }} whenCreated={mapInstance => {
            // This callback does not need to do anything for now
        }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {pickupLocation && <Marker position={[pickupLocation?.latitude, pickupLocation?.longitude]} icon={customPhotoIcon(pickup.imageId)}>
                <Popup>{pickup.label}</Popup>
            </Marker>}

            {checkinLocations && checkinLocations.map((checkIn, index) => (
                <Marker key={`checkin-${index}`} position={[checkIn.location.latitude, checkIn.location.longitude]} icon={customPhotoIcon(checkIn.status)}>
                    <Popup>{checkIn.remarks}</Popup>
                </Marker>
            ))}

            {dropoffLocation && <Marker position={[dropoffLocation?.latitude, dropoffLocation?.longitude]}
                     icon={customPhotoIcon(dropoff.imageId)}>
                <Popup>{dropoff.label}</Popup>
            </Marker>}

            {pickupLocation && dropoffLocation && <FitBoundsToMarkers markers={allMarkers}/>}
        </MapContainer>
        </Card>
    );
};

export default RouteMap;
