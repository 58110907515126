// AdditionalServiceAndPreferences.js

import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import InsuranceOptions from "./InsuranceOptions";
import DeliverySpeedOptions from "./DeliverySpeedOptions";
import TypeOfTransport from "./TypeOfTransport";
import SpecialHandlingOptions from "./SpecialHandlingOptions";
import React from "react";
import AdditionalServicesOptions from "./AdditionalServicesOptions";
import {
   accordionDetailsStyle,
   accordionStyle,
   accordionSummaryStyle,
   accordionTitleStyle
} from "../../lib/themes/theme";

const AdditionalServiceAndPreferences = ({mode}) =>{
   return (
      <Accordion sx={accordionStyle}>
         <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="additional-options-content"
            id="additional-options-header"
            sx={accordionSummaryStyle}
         >
            <Typography sx={accordionTitleStyle}>Additional Services & Preferences</Typography>
         </AccordionSummary>
         <AccordionDetails sx={accordionDetailsStyle}>
            <Grid container spacing={2}>
               <Grid item xs={6} sm={6} md={3}>
                  <InsuranceOptions mode={mode} />
               </Grid>
               <Grid item xs={6} sm={6} md={3}>
                  <DeliverySpeedOptions mode={mode} />
               </Grid>
               <Grid item xs={6} sm={6} md={3}>
                  <TypeOfTransport mode={mode} />
               </Grid>
               <Grid item xs={6} sm={6} md={3}>
                  <SpecialHandlingOptions mode={mode}/>
               </Grid>
               <Grid item xs={6} sm={6} md={3}>
                  <AdditionalServicesOptions mode={mode}/>
               </Grid>
            </Grid>
         </AccordionDetails>
      </Accordion>
   )
}
export default AdditionalServiceAndPreferences;
