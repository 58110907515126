// CreateReview.js
import React, {useMemo, useState} from 'react';
import {
    Box,
    Card,
    CardContent,
    TextField,
    Typography,
    Rating,
    IconButton,
    Modal,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {postDriverReview} from "../../lib/api";
import useCurrentUser from "../../lib/hooks/useCurrentUser";
import {useGlobal} from "../../lib/contexts";
import {LoadingButton} from "@mui/lab";
import useSummary from "../../lib/hooks/useSummary";
import UploadMediaDialog from "../UploadMediaDialog/UploadMediaDialog";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const CreateReview = () => {
    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState(2.5);
    const [reviewText, setReviewText] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [alreadyReviewed, setAlreadyReviewed] = useState(false);
    const { token, userID } = useCurrentUser();
    const { setSnackbar } = useGlobal();


    const { data, refetch } = useSummary()
    const shipment = useMemo(() => data?.route_info, [data])
    const driver = useMemo(() => data?.driver_info, [data])
    const handleSubmit = async () => {

        const reviewObject = {
            driver_id: driver?.id,
            order_id: shipment?.id,
            rating,
            comment: reviewText
            //photo: photo, needs to be a presigned url separate s3 request setup,
            //video: photo, needs to be a presigned url separate s3 request setup
        };
        setIsSaving(true);

        const result = await postDriverReview(token, userID, reviewObject);

        if (result.error) {
            console.error("Failed to save review:", result.error);
            setSnackbar({
                open: true,
                message: `Oops! Something went wrong. Couldn't save review: ${JSON.stringify(result.error)}`,
                severity: 'error',
            });
            setRating(0);
            setReviewText('');
            setOpen(false);
        } else {
            setOpen(true)
            setSnackbar({
                open: true,
                message: "Success! Review Saved.",
                severity: 'success',
            });
            refetch(); // You may need to await this as well if it returns a promise
        }

        setIsSaving(false);
    };

    const handleCloseModal = () => setOpen(false);

    return (
        <>
            <Card style={{minHeight:320}}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Share Your Experience
                    </Typography>
                    <Rating disabled={isSaving}
                        value={rating}
                        onChange={(event, newValue) => {
                            setRating(newValue);
                        }}
                        precision={0.5}
                    />
                    <TextField disabled={isSaving}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        label="Your Review"
                        margin="normal"
                        value={reviewText}
                        onChange={(e) => setReviewText(e.target.value)}
                    />
                    <Box display="flex" gap={2} mt={2}>
                        <UploadMediaDialog
                        orderID={shipment?.id}
                        entityID={driver?.id}
                        uploadType={'review_shipment'}
                        disabled={isSaving}
                        title={'Add a Photo or Video to your Review'}
                        onChange={()=>{}}
                        onSuccess={()=>{}}
                        onClose={()=>{}}
                        />
                        <LoadingButton disabled={!reviewText || !rating || isSaving}
                                       variant="contained" color="primary" onClick={handleSubmit} loading={isSaving}>
                            Submit Review
                        </LoadingButton>
                    </Box>
                </CardContent>
            </Card>
            <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="submit-feedback-title"
                aria-describedby="submit-feedback-description"
            >
                <Box sx={style}>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="submit-feedback-title" variant="h6" component="h2">
                        Thank You!
                    </Typography>
                    <Typography id="submit-feedback-description" sx={{ mt: 2 }}>
                        Your review has been submitted successfully.
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default CreateReview;
