// LogisticsMap.js

import React, {useEffect, useMemo} from 'react';
import {MapContainer, TileLayer, Marker, Popup, useMap} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {Box, Card, CardMedia, CircularProgress, Grid, Typography} from "@mui/material";
import useOrders from "../../lib/hooks/useOrders";
import {prettyDateTime, toCapitalizeFromUnderScore} from "../../lib/helpers/helpers";

// Custom icons
const pickupIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});
const dropoffIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: 'dropoff-icon' // Customize this class in your CSS for different colors if needed
});
const truckIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: 'truck-icon' // Customize this class in your CSS for different colors if needed
});

const customPhotoIcon = (url) => new L.Icon({
    iconUrl: url || require('leaflet/dist/images/marker-icon.png'),
    iconSize:  url ? [40, 40] : null,
    iconAnchor: [20, 40],
    popupAnchor: [0, -40]
});

const SetViewToBounds = ({ allLocations }) => {
    const map = useMap();
    useEffect(() => {
        if (allLocations.length > 0) {
            const bounds = L.latLngBounds(allLocations.map(loc => [loc.latitude, loc.longitude]));
            map.fitBounds(bounds);
        }
    }, [allLocations, map]);
    return null;
};
const PopupContent = ({ type, order, media, ...location }) => {
    // Helper to get the right timestamp based on the location type
    const getTimeByType = (type) => {
        if (type === 'pickup') return order.estimated_delivery_time;
        if (type === 'dropoff') return order.actual_delivery_date_time;
        if (type === 'checkin') return order.check_in_timestamp;
        return null;
    };

    const timeLabel = toCapitalizeFromUnderScore(type);
    const dateTime = getTimeByType(type);
    const formattedDateTime = dateTime ? prettyDateTime(dateTime) : 'N/A';
    const contactInfo = location.contact_name ? `${location.contact_name}, ${location.contact_phone}` : 'Contact information not available';

    return (
        <Box p={2}>
            <Typography variant="h6" gutterBottom>{timeLabel} Location: Order #{order.id}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1">{location.address}</Typography>
                    <Typography variant="body2">Status: {order.status}</Typography>
                    {dateTime && <Typography variant="body2">{timeLabel} Time: {formattedDateTime}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {media.length > 0 && (
                        <CardMedia
                            component="img"
                            image={media[0].s3_path} // Assuming 's3_path' is correct
                            alt={`Location visual for ${type}`}
                            sx={{ width: '100%', height: 'auto' }}
                        />
                    )}
                    {media.length === 0 && <Typography variant="body2">No image available.</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2">{contactInfo}</Typography>
                    <Typography variant="body2">Email: {location.contact_email || 'N/A'}</Typography>
                    <Typography variant="caption">Last updated: {prettyDateTime(order.updated_at)}</Typography>
                </Grid>
                {order.vehicles && order.vehicles.length > 0 && (
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">Vehicle Details:</Typography>
                        {order.vehicles.map(vehicle => (
                            <Typography key={vehicle.id} variant="body2">
                                {vehicle.make} {vehicle.model} ({vehicle.year})
                            </Typography>
                        ))}
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

const LogisticsMap = ({fullSize = false}) => {
    const { data, isLoading, isRefetching, refetch } = useOrders();

    const allLocations = useMemo(() => {
        // Map over the data array to get each item's locations array
        // For each item, map over its locations and attach the item's data under the 'order' key
        const locations = data?.map(item =>
            (item?.locations || []).map(location => ({
                ...location, // Spread the existing location properties
                order: { ...item } // Attach the rest of the item's data under the 'order' key
            }))
        ).flat(); // Flatten the array of arrays into a single array
        return locations;
    }, [data]);

//console.log('allLocations',allLocations)
    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Card>
            <MapContainer center={[37.0902, -95.7129]} zoom={4} style={{ height: fullSize ? 1024 : 350, width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {allLocations?.map(({ type, id, latitude, longitude, media, order }) => (
                    <>
                        {latitude && longitude && (
                            <Marker key={`${type}-${id}`} position={[latitude, longitude]}
                                    icon={customPhotoIcon(media[0]?.s3_path)}
                            >
                                <Popup>
                                    <PopupContent type={type} order={order} media={media} />

                                    {/* {type === 'pickup' && (
                                        <>
                                            <strong>Pick-up Location:</strong> Order #{id}<br/>
                                            {estimated_delivery_time &&
                                                <>Estimated Pick-up: {new Date(estimated_delivery_time).toLocaleString()}</>}
                                            <br/>
                                            <img src={media[0]} alt="Location visual" style={{width: '100px'}}/>
                                        </>
                                    )}
                                    {type === 'dropoff' && (
                                        <>
                                            <strong>Drop-off Location:</strong> Order #{id}<br />
                                            Estimated Arrival: {new Date(actual_delivery_date_time).toLocaleString()}<br />
                                            <img src={media[0]} alt="Location visual" style={{ width: '100px' }} />
                                        </>
                                    )}
                                    {type === 'checkin' && (
                                        <>
                                            <strong>Check-In Location:</strong> Order #{id}<br />
                                            Check-In Time: {new Date(checkin_timestamp).toLocaleString()}<br />
                                            <img src={media[0]} alt="Location visual" style={{ width: '100px' }} />
                                        </>
                                    )}*/}
                                </Popup>
                            </Marker>
                        )}
                    </>
                ))}
                <SetViewToBounds allLocations={allLocations} />
            </MapContainer>
        </Card>
    );
};
export default LogisticsMap;
