// QuoteItemDetail.js
import React, {useState} from 'react';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import {LoadingButton} from "@mui/lab";
import {OrderClaimDialog} from "../OrderClaimDialog/OrderClaimDialog";

const QuoteItemDetail = ({ quote, mode }) => {
    const [openClaimDialog, setOpenClaimDialog] = useState(false)



    const renderContentByMode = () => {
      switch (mode) {
         case "auto":
            return (
               <>
                  <Typography sx={{ display: 'block' }} component="span" variant="body2" color="text.primary">
                     {quote.vehicle_make_model && `Vehicle: ${quote.vehicle_make_model}`}
                  </Typography>
               </>
            );
         case "material":
            return (
               <>
                  <Typography variant="body2">
                     Material Type: {quote.materialType}
                  </Typography>
                  <Typography variant="body2">
                     Total Weight: {quote.totalWeight} lbs
                  </Typography>
               </>
            );
         case "marine":
            return (
               <>
                  <Typography variant="body2">
                     Vessel Type: {quote.vesselType}
                  </Typography>
                  {quote.vesselName && (
                     <Typography variant="body2">
                        Vessel Name: {quote.vesselName}
                     </Typography>
                  )}
               </>
            );
         default:
            return null;
      }
   };

   return (
      <ListItem alignItems="flex-start" secondaryAction={
         <LoadingButton
            loading={openClaimDialog}
            variant="outlined"
            //onClick={handleClaimQuote}
            onClick={() => setOpenClaimDialog(true)}
         >
            Claim
         </LoadingButton>
      }
                sx={{
                   // Adjust the padding or margin if necessary to ensure the button does not overlap the text
                   '.MuiListItemText-multiline': {
                      marginRight: (theme) => theme.spacing(7), // Adjust the spacing value accordingly
                   }
                }}
      >
         <ListItemText
            primary={`Price: $${quote.price}`}
            secondary={
               <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                  {renderContentByMode()}
                  <Typography variant="body2">
                     <b>Pick-up:</b> {quote.pickup.location.address}
                      {/*<br/>on {quote.pickup.date}*/}
                  </Typography>
                  <Typography  variant="body2">
                     <b>Drop-off:</b> {quote.dropoff.location.address}
                      {/*<br/>on {quote.dropoff.date}*/}
                  </Typography>
                  {quote.specialRequirements && (
                     <Typography variant="body2">
                        <b>Special Requirements:</b><br/>{quote.specialRequirements}
                     </Typography>
                  )}
               </Box>
            }
         />

          <OrderClaimDialog isOpen={openClaimDialog}
                            quote={quote}
                               onClose={()=>setOpenClaimDialog(false)}
          />
      </ListItem>
   );
};

export default QuoteItemDetail;
