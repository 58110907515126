import TrackForm from "../../components/TrackForm/TrackForm";
import Screen from "../../components/Screen/Screen";
import React from "react";

const TrackingDashboard = () => {
   return (
      <>
         <Screen title={'Track Order'}>
            <TrackForm/>
         </Screen>
      </>
   )
}
export default TrackingDashboard
