import { useQuery } from 'react-query';
import useCurrentUser from "./useCurrentUser";
import {getOrders} from "../api/getOrders";

const useOrders = (orderID= '') => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('orders_'+ orderID, () => getOrders(orderID, token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000///50000,
  });
  return result;
};

export default useOrders;
