import axios from "axios";
import { API_HOST } from "../constants/constants";

export const getValidateSSOLink = async (quoteId, checkInToken) => {
    if (!quoteId || !checkInToken) {
        return { valid: false, error: 'Quote ID or Check-In Token missing' };
    }

    const apiURL = `${API_HOST}/auth/sso_validate`;

    try {
        const response = await axios.get(apiURL, {
            params: {
                quoteId: quoteId,
                token: checkInToken
            },
            headers: {
                'Content-Type': 'application/json',
                // Include any additional headers like Authorization if required
            }
        });
        return response.data
        //return { valid: response.data.success, token: response.data.token, error: null }; // Assuming the API returns an object with a boolean `isValid` field
    } catch (error) {
        console.error('Error validating SSO link:', error);
        return { valid: false, error: error.response?.data.message || 'An error occurred during SSO validation' };
    }
};
