import React, { useState } from 'react';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    CardMedia, List, ListItem, ListItemText,
} from "@mui/material";
import MediaModal from "../MediaModal/MediaModal";
import RefreshButton from "../RefreshButton/RefreshButton";
import useMedia from "../../lib/hooks/useMedia";

const RecentMedia = () => {
    const [open, setOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState({});

    const { data, isLoading, isRefetching, refetch } = useMedia()

    const handleOpen = (media) => {
        setSelectedMedia(media);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Recent Media
                    <RefreshButton onRefresh={refetch} isLoading={isLoading || isRefetching} />
                </Typography>
                <Grid container spacing={2}>
                    {
                        data && data.length > 0 ? (
                            data.map((media) => (
                                <Grid item xs={12} sm={6} md={4} lg={4} key={media.id} onClick={() => handleOpen(media)}>
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            sx={{ width: '100%', height: 'auto', maxHeight: 180 }} // Ensures aspect ratio is maintained
                                            image={media.src}
                                            alt={media.caption || media.associated_details.location_address}
                                            title={media.caption || media.associated_details.location_address || media.associated_details.location_coordinates}
                                        />
                                       {/* <CardContent>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                <List>
                                                        <ListItem key={media.id}>
                                                            <ListItemText secondary={media.associated_details.location_address} />
                                                        </ListItem>
                                                </List>
                                            </Typography>
                                        </CardContent>*/}
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Typography variant="subtitle1" sx={{ margin: 2 }}>
                                No media available
                            </Typography>
                        )
                    }

                </Grid>
            </CardContent>
            <MediaModal open={open} handleClose={handleClose} media={selectedMedia} />
        </Card>
    );
};

export default RecentMedia;
