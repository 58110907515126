import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    IconButton,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress, TextField, FormControl, FormLabel
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import UploadMediaDialog from "../UploadMediaDialog/UploadMediaDialog";
import {useState} from "react";
import {useQuote} from "../../lib/contexts/QuoteContext";
import TransportID from "../QuoteForm/VehicleVIN";
import {VehicleTypeSelect, VehicleMakeModelSelect, VehicleYearSelect, VehicleMakeSelect, VehicleModelSelect} from "../QuoteForm/VehicleSelection";
import {toCapitalizeFromUnderScore} from "../../lib/helpers/helpers";
import {useGlobal} from "../../lib/contexts";
import {postDriverReview, postVehicle} from "../../lib/api";
import useCurrentUser from "../../lib/hooks/useCurrentUser";

export default function VINManagementTable({ quoteID = null, label = 'Vehicles' }) {
    const {
        vehicles, setVehicles
    } = useQuote();
    const { setSnackbar } = useGlobal();
    const { token, userID } = useCurrentUser();
    const [isSaving, setIsSaving] = useState(false);
    const [openBulkUpload, setOpenBulkUpload] = useState(false);
    const [bulkUploadCSV, setBulkUploadCSV] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedVIN, setSelectedVIN] = useState(null);
    const [newRow, setNewRow] = useState({ vin: '', make: '', model: '', year: '', vehicle_type: '', curb_weight: '', inoperable: false });

    const handleBulkUpload = () => {
        if (!bulkUploadCSV) return; // Return early if there's no CSV data

        // Split the CSV into lines
        const lines = bulkUploadCSV.trim().split(/\r?\n/);

        // Parse each line into an object
        const newVehicles = lines.map(line => {
            const parts = line.split(',').map(part => part.trim()); // Split by comma and trim whitespace

            // Return a new vehicle object, assuming the parts array is in the correct order
            return {
                vin: parts[0],
                make: parts[1],
                model: parts[2],
                year: parseInt(parts[3]), // Convert year to integer
                vehicle_type: parts[4],
                curb_weight: parseInt(parts[5]), // Convert weight to integer
                inoperable: parts[6]?.toLowerCase() === 'true' // Convert inoperable status to boolean
            };
        });

        // Check for duplicates within the new CSV upload
        const vinSet = new Set();
        const hasDuplicateVIN = newVehicles.some(vehicle => {
            if (vinSet.has(vehicle.vin)) {
                return true;
            } else {
                vinSet.add(vehicle.vin);
                return false;
            }
        });

        // Filter out any invalid vehicles based on additional criteria
        const validVehicles = newVehicles.filter(newVehicle => {
            const isBadVIN = newVehicle.vin.length !== 17 || vehicles.some(vehicle => vehicle.vin === newVehicle.vin);
            return !isBadVIN &&
                newVehicle.make !== '' &&
                newVehicle.model !== '' &&
                newVehicle.year > 0 &&
                newVehicle.vehicle_type !== '' &&
                newVehicle.curb_weight > 0 &&
                newVehicle.inoperable !== '';
        });

        // Verify the validVehicles array is not empty and correctly formed
        if (validVehicles.length > 0 && !hasDuplicateVIN) {
            // Append new valid vehicles to existing vehicles
            setVehicles([...vehicles, ...validVehicles]);

            // Close the bulk upload modal and clear the CSV input field
            setOpenBulkUpload(false);
            setBulkUploadCSV('');
        } else {
            // Optionally handle the error case if the CSV data is incorrect or has duplicates
            let errorMessage = "Invalid CSV data provided. Please ensure all entries are correct and complete.";
            if (hasDuplicateVIN) {
                errorMessage += " Duplicate VIN found in the upload.";
            }
            setSnackbar({
                open: true,
                message: errorMessage,
                severity: 'error',
            });
        }
    };

    const handleOpenDeleteDialog = (vin) => {
        setOpenDelete(true);
        setSelectedVIN(vin);
    };

    const handleCloseDialogs = () => {
        setOpenDelete(false);
        setOpenBulkUpload(false);
    };

    const handleDelete = () => {
        setVehicles(vehicles.filter(item => item.vin !== selectedVIN.vin));
        handleCloseDialogs();
    };

    const handleInputChange = (e, field) => {
        setNewRow({ ...newRow, [field]: e.target.value });
    };

    const handleSaveNewRow = async () => {
        setIsSaving(true);
        const result = await postVehicle(token, userID, {
            vin: newRow.vin,
            make: newRow.make,
            model: newRow.model,
            year: newRow.year,
            vehicle_type: newRow.vehicle_type,
            inoperable: newRow.inoperable,
            gross_vehicle_weight: newRow.curb_weight
        });

        setIsSaving(false);
        if (result.error) {
            console.error("Failed to add vehicle:", result.error);
            setSnackbar({
                open: true,
                message: `Oops! Something went wrong. Couldn't add vehicle: ${JSON.stringify(result.error)}`,
                severity: 'error',
            });
            return false
        } else {
            setSnackbar({
                open: true,
                message: "Success! VIN added.",
                severity: 'success',
            });
            setVehicles([...vehicles, newRow]);
            setNewRow({ vin: '', make: '', model: '', year: '', vehicle_type: '', curb_weight: '', inoperable: false }); // reset new row
        }
    };
// Checks if the VIN length is incorrect or if the VIN already exists in the vehicles array
    const isBadVIN = newRow.vin.length !== 17 || vehicles.some(vehicle => vehicle.vin === newRow.vin);

    const canAddNewRow =
        !isBadVIN &&
        newRow.make !== '' &&
        newRow.model !== '' &&
        newRow.year !== '' &&
        newRow.vehicle_type !== '' &&
        newRow.curb_weight !== '' &&
        newRow.inoperable !== '';

    return (
        <>
            <Typography style={{display:'inline'}} variant="h6" gutterBottom>{label}</Typography>
            <Button style={{marginLeft:20}} size={'small'} color={'secondary'} variant={'outlined'}
            onClick={() => setOpenBulkUpload(true)}>Bulk Upload</Button>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">&nbsp;</TableCell>
                            <TableCell>VIN</TableCell>
                            <TableCell align="right">Make</TableCell>
                            <TableCell align="right">Model</TableCell>
                            <TableCell align="right">Year</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Curb Weight</TableCell>
                            <TableCell align="right">Inoperable?</TableCell>
                            <TableCell align="right">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicles.map((row) => (
                            <TableRow key={row.vin} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="right">
                                    <UploadMediaDialog
                                        entityID={row.vin}
                                        quoteID={quoteID}
                                        uploadType={'vehicle'}
                                        disabled={isSaving}
                                        title={'Add a Photo or Video to VIN: ' + row.vin}
                                        onChange={()=>{}}
                                        onSuccess={()=>{}}
                                        onClose={()=>{}}
                                    />
                                </TableCell>
                                <TableCell align="right">{row.vin}</TableCell>
                                <TableCell align="right">{row.make}</TableCell>
                                <TableCell align="right">{row.model}</TableCell>
                                <TableCell align="right">{row.year}</TableCell>
                                <TableCell align="right">{toCapitalizeFromUnderScore(row.vehicle_type)}</TableCell>
                                <TableCell align="right">
                                    {row.gross_vehicle_weight ? row.gross_vehicle_weight.toLocaleString() : 'N/A'}
                                </TableCell>
                                <TableCell align="right">{String(!row.is_operable)}</TableCell>
                                <TableCell align="right">
                                    <IconButton
                                        disabled={isSaving}
                                        onClick={() => handleOpenDeleteDialog(row)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell style={{minWidth:100}}>
                                <TransportID error={isBadVIN} viewMode={'new_vin'} size={'small'} value={newRow.vin} onChange={(e) => handleInputChange(e, 'vin')}/>
                            </TableCell>
                            <TableCell style={{minWidth:120}}>

                                <VehicleMakeSelect
                                    size={'small'} value={newRow.make} onChange={(e) => handleInputChange(e, 'make')} />
                            </TableCell>
                            <TableCell style={{minWidth:130}}>
                                <VehicleModelSelect
                                    make={newRow.make}
                                    size={'small'} value={newRow.model} onChange={(e) => handleInputChange(e, 'model')} />
                            </TableCell>
                            <TableCell style={{minWidth:130}}>

                                <VehicleYearSelect
                                    size={'small'} value={newRow.year} onChange={(e) => handleInputChange(e, 'year')} />
                            </TableCell>
                            <TableCell style={{minWidth:130}}>
                                <VehicleTypeSelect size={'small'} value={newRow.vehicle_type} onChange={(e) => handleInputChange(e, 'vehicle_type')}/>
                                {/*
                                <VesselTypeSelect mode={'auto'} placeholder="Type" size={'small'} value={newRow.vehicle_type} onChange={(e) => handleInputChange(e, 'vehicle_type')} />
*/}
                            </TableCell>
                            <TableCell style={{minWidth:110}}>
                                <FormControl fullWidth margin="normal"
                                             size={'small'}>
                                    <TextField
                                        label={'Weight'}
                                        size={'small'} value={newRow.curb_weight} onChange={(e) => handleInputChange(e, 'curb_weight')} placeholder="500"
                                        variant="outlined"/>
                                </FormControl>
                            </TableCell>
                            <TableCell style={{minWidth:120}}>
                                <FormControl fullWidth margin="normal"
                                             size={'small'}>
                                <TextField
                                    label={'Inoperable?'}
                                    size={'small'}
                                    select
                                    value={newRow.inoperable}
                                    onChange={(e) => handleInputChange(e, 'inoperable')}
                                    SelectProps={{ native: true }}
                                    variant="outlined"
                                    fullWidth
                                >
                                    <option value={false}>No</option>
                                    <option value={true}>Yes</option>
                                </TextField>
                                </FormControl>
                            </TableCell>
                            <TableCell align="right" >
                                <Button
                                    disabled={!canAddNewRow || isSaving}
                                    onClick={handleSaveNewRow} startIcon={<SaveIcon />}>Add</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedVIN && <Dialog open={openDelete} onClose={handleCloseDialogs} aria-labelledby="alert-dialog-title"
                     aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the vehicle?<br/><br/>
                        {selectedVIN.make} {selectedVIN.model} {selectedVIN.year} - <b>{selectedVIN.vin}</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogs}>Cancel</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>}
            {openBulkUpload && <Dialog open={openBulkUpload} onClose={handleCloseDialogs} aria-labelledby="alert-dialog-title"
                     aria-describedby="alert-bulk-description" maxWidth={'sm'} fullWidth={true}>

                <DialogTitle id="alert-bulk-title">{"Bulk Upload Vehicles"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-bulk-description">
                        <TextField
                            placeholder="To bulk upload, please enter a CSV pattern per line: VIN, Make, Model, Year, Type, Weight, In-Op Status"
                        multiline={true}
                        minRows={3}
                            fullWidth={true}
                            value={bulkUploadCSV}
                            onChange={(e) => setBulkUploadCSV(e.target.value)}
                            error={bulkUploadCSV.length > 0 && !isValidCSV(bulkUploadCSV)}
                            helperText={bulkUploadCSV.length > 0 && !isValidCSV(bulkUploadCSV) ? 'Each line must include exactly six commas (7 fields): VIN, Make, Model, Year, Type, Weight, In-Op Status.' : ''}
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialogs}>Cancel</Button>
                    <Button
                        disabled={!(bulkUploadCSV.length > 0 && isValidCSV(bulkUploadCSV))}
                        onClick={handleBulkUpload} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>}
        </>
    );
}
function isValidCSV(csvString) {
    if (!csvString.trim()) return false;  // Early return for empty strings after trimming whitespace

    const lines = csvString.trim().split(/\r?\n/);  // Split into lines
    return lines.every(line => {
        const commas = line.match(/,/g) || [];
        return commas.length === 6;  // Check if there are exactly six commas per line
    });
}
