// MaterialTypeAndGradeFields.js
import React, {useEffect, useState} from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

/*
// Material Grades Pricing Impact:
Handling Requirements: Higher-grade materials may require special handling procedures to maintain their integrity during transport. For example, certain grades of steel that are prone to corrosion may need protective packaging or controlled environments, which can increase costs.
   
   Value and Insurance: Higher-grade materials are often more valuable, increasing the insurance costs during transport. The higher value may necessitate additional security measures or more expensive insurance coverage to protect against loss or damage.
   
   Regulatory and Safety Requirements: Some material grades, especially in chemicals or hazardous materials, may be subject to stricter regulatory requirements for transportation. Complying with these regulations can involve special certifications for carriers, specialized equipment, and additional documentation, all of which contribute to higher costs.
   
   Rarity and Availability: Rarer materials or specific grades might only be available from certain locations, potentially increasing transportation distances and costs. Additionally, the rarity of certain grades might lead to a premium charge due to the specialized nature of transport and handling required.
   
   Condition on Arrival: Certain grades of materials, especially those used in manufacturing or construction, may have stringent requirements for condition upon arrival. Ensuring that these materials are transported in a way that prevents damage or degradation can increase transportation costs due to the need for specialized containers or vehicles.
   
   Market Demand: The demand for specific material grades can also influence transportation costs. High-demand grades may command premium transport services, especially if timely delivery is crucial to the buyer.
   */
const materialTypes = [
   { value: 'steel', label: 'Steel' },
   { value: 'aluminum', label: 'Aluminum' },
   { value: 'copper', label: 'Copper' },
   { value: 'plastic', label: 'Plastic' },
   { value: 'concrete', label: 'Concrete' },
   { value: 'wood', label: 'Wood' },
   { value: 'glass', label: 'Glass' },
   { value: 'rubber', label: 'Rubber' },
   { value: 'textiles', label: 'Textiles' },
   { value: 'chemicals', label: 'Chemicals' },
   { value: 'paper', label: 'Paper' },
   { value: 'ceramics', label: 'Ceramics' },
   { value: 'composite_materials', label: 'Composite Materials' },
   { value: 'biomaterials', label: 'Biomaterials' },
   { value: 'precious_metals', label: 'Precious Metals (Gold, Silver, Platinum)' },
   { value: 'building_materials', label: 'Building Materials (Brick, Tile, Mortar)' },
   { value: 'agricultural_products', label: 'Agricultural Products (Grains, Livestock feed)' },
   { value: 'petroleum_products', label: 'Petroleum Products (Crude oil, Gasoline, Diesel)' },
   { value: 'natural_gas', label: 'Natural Gas' },
   { value: 'coal', label: 'Coal' },
   { value: 'minerals', label: 'Minerals (Quartz, Limestone, Sand)' },
   { value: 'electronic_components', label: 'Electronic Components' },
   { value: 'automotive_parts', label: 'Automotive Parts' },
   { value: 'machinery', label: 'Machinery and Equipment' },
   { value: 'furniture', label: 'Furniture' },
   { value: 'food_beverages', label: 'Food & Beverages' },
   { value: 'pharmaceuticals', label: 'Pharmaceuticals' },
   { value: 'waste', label: 'Waste & Refuse' },
   { value: 'recyclable_materials', label: 'Recyclable Materials' },
   { value: 'aerospace_components', label: 'Aerospace Components' },
   { value: 'medical_supplies', label: 'Medical Supplies' },
   { value: 'construction_equipment', label: 'Construction Equipment' },
   { value: 'packaging_materials', label: 'Packaging Materials' },
   { value: 'livestock', label: 'Livestock' },
   { value: 'other', label: 'Other' }
];
const materialGrades = {
   steel: [
      'ASTM A36', // Common structural steel
      'ASTM A572 Grade 50', // High-strength low-alloy structural steel
      'AISI 304', // Stainless steel
      'AISI 316'  // Marine grade stainless
   ],
   aluminum: [
      '1100', // Commercially pure aluminum
      '2024', // Aircraft alloy
      '3003', // General-purpose alloy
      '6061', // Versatile alloy used in structural materials
      '7075'  // Aircraft grade
   ],
   copper: [
      'C11000', // Electrolytic tough pitch (ETP) copper
      'C12200'  // Phosphorus-deoxidized copper
   ],
   plastic: [
      'HDPE', // High-density polyethylene
      'LDPE', // Low-density polyethylene
      'PP',   // Polypropylene
      'PVC',  // Polyvinyl chloride
      'PET'   // Polyethylene terephthalate
   ],
   concrete: [
      'C20/25', // Standard strength concrete
      'C30/37', // High strength concrete
      'C40/50', // Very high strength concrete
   ],
   glass: [
      'Float', // Basic flat glass
      'Tempered', // Heat-strengthened for safety
      'Laminated' // Multi-layer safety glass
   ],
   rubber: [
      'Natural',
      'Nitrile',
      'EPDM', // Ethylene Propylene Diene Monomer
      'Silicone'
   ],
   // Examples for other materials omitted for brevity, add as needed
   textiles: ['Cotton', 'Polyester', 'Wool'],
   chemicals: ['Acids', 'Bases', 'Solvents',
      'Sulfuric Acid', // H2SO4, widely used in industry
      'Sodium Hydroxide', // NaOH, also known as lye and caustic soda
      'Ethanol', // C2H5OH, used as a solvent and in many industrial applications
      'Chlorine', // Cl2, used in disinfection and in the production of polymers
      'Ammonia' // NH3, used in agriculture and cleaning products
      ],
   paper: [
      'Uncoated', // Standard printing paper
      'Coated', // Glossy paper for high-quality printing
      'Cardstock', // Thick paper used for business cards, postcards, etc.
      'Recycled' // Made from recycled paper
   ],
   ceramics: [
      'Earthenware', // Low-fired, porous ceramics
      'Stoneware', // Non-porous ceramics fired at high temperatures
      'Porcelain', // Fine, high-strength, and translucent ceramics
      'Technical' // Ceramics used for engineering applications
   ],
   composite_materials: [
      'Fiberglass', // Glass fiber reinforced polymer
      'Carbon Fiber', // Carbon fiber reinforced polymer
      'Kevlar', // Aramid fiber composites for high strength and toughness
      'Composite Lumber' // Wood/plastic composite materials
   ],
   biomaterials: [
      'Collagen', // Natural biomaterial for medical and cosmetic applications
      'Hyaluronic Acid', // Used in medical and skincare products
      'PLA', // Polylactic acid, a biodegradable plastic
      'Titanium' // Widely used in implants and prosthetics
   ],
   precious_metals: [
      '24K Gold', // Pure gold
      'Sterling Silver', // Alloy containing 92.5% silver
      'Platinum', // High-purity platinum used in jewelry and industrial applications
      'Palladium' // Used in electronics and jewelry
   ],
   building_materials: [
      'Portland Cement', // Most common type of cement in general use
      'Gypsum Board', // Drywall
      'Structural Steel', // Steel construction materials
      'Insulation' // Materials used to reduce heat transfer
   ],
   agricultural_products: [
      'Corn', // Grain
      'Soybeans', // Legume
      'Cotton', // Fiber crop
      'Wheat' // Cereal grain
   ],
   petroleum_products: [
      'Crude Oil',
      'Gasoline',
      'Diesel',
      'Jet Fuel'
   ],
   natural_gas: ['Methane'], // Primary component of natural gas
   coal: [
      'Anthracite', // High carbon content and energy density, the highest grade of coal
      'Bituminous', // The most abundant form of coal
      'Sub-bituminous', // Lower heating value than bituminous
      'Lignite' // Lowest rank of coal, also known as brown coal
   ],
   minerals: [
      'Quartz',
      'Limestone',
      'Granite',
      'Marble'
   ],
   electronic_components: ['Resistors', 'Capacitors', 'Inductors', 'Semiconductors'],
   automotive_parts: ['Engines', 'Transmission Systems', 'Brakes', 'Suspension Systems'],
   machinery: ['Construction', 'Agricultural', 'Manufacturing', 'Mining Equipment'],
   furniture: ['Wood', 'Metal', 'Plastic', 'Upholstered'],
   food_beverages: ['Packaged Foods', 'Fresh Produce', 'Beverages', 'Dairy Products',
      'Frozen Foods', // Requires temperature-controlled transport
      'Fresh Produce', // Highly perishable, needs quick and careful handling
      'Meat and Poultry', // Requires refrigeration and health inspection standards
      'Dairy Products', // Includes milk, cheese, and requires cool temperature
      'Canned Goods'
   ],
   pharmaceuticals: ['OTC Medications', 'Prescription Drugs', 'Vaccines', 'Biologics'],
   waste: ['Municipal Solid Waste', 'Industrial Waste', 'Hazardous Waste', 'Medical Waste'],
   recyclable_materials: ['Paper', 'Plastics', 'Metals', 'Glass'],
   aerospace_components: ['Aerostructures', 'Avionics', 'Propulsion Systems', 'Satellite Components'],
   medical_supplies: ['Surgical Instruments', 'Diagnostic Supplies', 'Disposable Products', 'Orthopedic Implants'],
   construction_equipment: ['Excavators', 'Loaders', 'Cranes', 'Concrete Mixers'],
   packaging_materials: ['Cardboard', 'Plastic Films', 'Foam', 'Bubble Wrap'],
   livestock: [
      'Cattle', // Includes both dairy and beef breeds
      'Poultry', // Chickens, turkeys, and other birds raised for meat or eggs
      'Swine', // Pigs, both for meat production and breeding
      'Sheep and Goats', // Wool, meat, and milk production
      'Horses',
      'Other Livestock'
   ],
   other: ['Custom Materials or Products not listed']
};
const MaterialTypeAndGradeFields = () => {
   const { materialType, setMaterialType, materialGrade, setMaterialGrade } = useQuote();
   const [gradeOptions, setGradeOptions] = useState([]);
   
   useEffect(() => {
      // Dynamically set grade options based on the selected material type
      if (materialType) {
         const grades = materialGrades[materialType] || [];
         setGradeOptions(grades.map((grade) => ({ label: grade })));
      } else {
         setGradeOptions([]);
      }
   }, [materialType]);
   return (
      <Box>
         <Autocomplete
            options={materialTypes}
            getOptionLabel={(option) => option.label}
            value={materialTypes.find(option => option.value === materialType) || null}
            onChange={(event, newValue) => {
               setMaterialType(newValue ? newValue.value : '');
            }}
            renderInput={(params) => (
               <TextField {...params} label="Material Type" margin="normal" />
            )}
         />
         <Autocomplete
            freeSolo
            options={gradeOptions}
            getOptionLabel={(option) => option.label || ''}
            value={{ label: materialGrade || '' }}
            onChange={(event, newValue) => {
               setMaterialGrade(typeof newValue === 'string' ? newValue : newValue?.label || '');
            }}
            renderInput={(params) => (
               <TextField {...params} label="Material Grade" margin="normal" />
            )}
         />
      </Box>
   );
};

export default MaterialTypeAndGradeFields;
