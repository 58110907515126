//TrackContext.js
import React, { createContext, useContext, useState } from 'react';

const TrackContext = createContext();

export const useTrack = () => useContext(TrackContext);

export const TrackProvider = ({ children }) => {
   const [orders, setOrders] = useState([]);
   const [trackId, setTrackId] = useState('');
   const [isTracking, setIsTracking] = useState(false);
   const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'info',
   });

   const value = {
      trackId, setTrackId,
      isTracking, setIsTracking,
      snackbar, setSnackbar,
      orders, setOrders,
   };

   return (
      <TrackContext.Provider value={value}>
         {children}
      </TrackContext.Provider>
   );
};
