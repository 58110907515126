// VehicleQuoteForm.js
import React from 'react';
import LocationAutocomplete from "../LocationAutocomplete/LocationAutocomplete";
import VehicleSelection from "./VehicleSelection";
import { Stack} from "@mui/material";
import VehicleVIN from "./VehicleVIN";
import AdditionalServiceAndPreferences from "./AdditionalServiceAndPreferences";
import {useQuote} from "../../lib/contexts/QuoteContext";

const VehicleQuoteForm = ({ }) => {
   const {
      pickupLocation, setPickupLocation,
      dropoffLocation, setDropoffLocation,
      vehicleType, setVehicleType
   } = useQuote();

   return (
      <Stack direction="column" spacing={2}>
         <LocationAutocomplete
            label="Pick-up Location"
            value={pickupLocation}
            onPlaceSelected={setPickupLocation}
         />
         <LocationAutocomplete
            label="Drop-off Location"
            value={dropoffLocation}
            onPlaceSelected={setDropoffLocation}
         />
         <VehicleVIN mode={"auto"}  />
         <VehicleSelection />

{/*

// todo - restore later
         <AdditionalServiceAndPreferences mode={'auto'} />
*/}
      </Stack>
   );
};

export default VehicleQuoteForm;
