import Screen from "../../components/Screen/Screen";
import React from "react";
import {Typography} from "@mui/material";

const ReportDashboard = () => {
   return (
      <>
         <Screen title={'Report Dashboard'}>
            <Typography>Coming Soon</Typography>
         </Screen>
      </>
   )
}
export default ReportDashboard
