// PhoneNumberInput.js
import React from 'react';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';

function PhoneNumberInput({ label, id, value, onChange, disabled }) {
    const [error, setError] = React.useState(false);

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        // Simple validation check for US phone numbers
        const regex = /^\(\d{3}\) \d{3}-\d{4}$/;
        if (value.match(regex) || value === '') {
            setError(false);
        } else {
            setError(true);
        }
        onChange(e);  // Pass the event to the parent component's handler
    };

    return (
        <InputMask
            mask="(999) 999-9999"
            value={value}
            disabled={disabled}
            onChange={handlePhoneChange}
        >
            {() => (
                <TextField
                    margin="dense"
                    id={id}
                    label={label}
                    type="tel"
                    fullWidth
                    variant="outlined"
                    error={error}
                    helperText={error ? "Invalid phone number format" : ""}
                />
            )}
        </InputMask>
    );
}

export default PhoneNumberInput;
