import React, { useState } from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, TextField } from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

const InsuranceOptions = () => {
   const { insurance, setInsurance, insuranceRequired, setInsuranceRequired, insuranceValue, setInsuranceValue, setShowInsuranceValue } = useQuote();
   
   const handleInsuranceRequiredChange = (event) => {
      setInsuranceRequired(event.target.checked);
      setShowInsuranceValue(event.target.checked);
      // If insurance is not required, clear the value
      if (!event.target.checked) {
         setInsuranceValue('');
      }
   };
   
   return (
      <FormControl component="fieldset" fullWidth>
         <FormLabel component="legend">Insurance Options</FormLabel>
         <FormGroup>
            <FormControlLabel
               control={<Checkbox checked={insuranceRequired} onChange={handleInsuranceRequiredChange} />}
               label="Insurance Required"
            />
         </FormGroup>
         {insuranceRequired && (
            <RadioGroup
               aria-label="insurance"
               value={insurance}
               onChange={(e) => setInsurance(e.target.value)}
            >
               <FormControlLabel value="standard" control={<Radio />} label="Standard Coverage" />
               <FormControlLabel value="premium" control={<Radio />} label="Premium Coverage" />
               <TextField
                  label="Insurance Value ($)"
                  type="number"
                  value={insuranceValue}
                  onChange={(e) => setInsuranceValue(e.target.value)}
                  fullWidth
                  margin="normal"
               />
            </RadioGroup>
         )}
      </FormControl>
   );
};

export default InsuranceOptions;
