import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './lib/assets/fonts/remixicon.css';
import './lib/assets/css/style.css';

import SignUp from './screens/SignUp/SignUp';
import Error404 from './screens/Error404/Error404';
import {LoginScreen} from "./screens/LoginScreen";
import {
    OrganizationContextProvider,
    UserContextProvider,
    ThemeContext,
    OrganizationContext,
    ModeProvider, useMode, GlobalProvider, UserContext, useGlobal
} from "./lib/contexts";
import {ThemeProvider, StyledEngineProvider, Snackbar} from "@mui/material";
import {SnackbarProvider} from "notistack";
import {QueryClient, QueryClientProvider} from "react-query";
import React, {useContext} from "react";
import Dashboard from "./components/Dashboard/Dashboard";
import {DemoContextProvider} from "./lib/contexts/DemoContext";
import useCurrentUser from "./lib/hooks/useCurrentUser";
import CarrierPortal from "./screens/CarrierPortal";
import {theme} from "./lib/themes/theme";
import TrackingDashboard from "./screens/TrackingDashboard";
import CurrentShipmentsProgress from "./components/Dashboard/CurrentShipmentsProgress";
import MapDashboard from "./screens/MapDashboard";
import ShipmentDashboard from "./screens/ShipmentDashboard";
import ReportDashboard from "./screens/ReportDashboard";
import QuoteFormScreen from "./screens/QuoteFormScreen";
import {getValidateSSOLink} from "./lib/api";
import {Alert} from "@mui/lab";
function SSOHandler() {
    const { quoteId, checkInToken } = useParams();
    const navigate = useNavigate();
    const {
        actions: { setUser },
    } = useContext(UserContext);
    React.useEffect(() => {
        // Assuming you have a function to validate the SSO link
        getValidateSSOLink(quoteId, checkInToken).then((data) => {
            if (data.success) {
                localStorage.setItem(
                    'AUTH',
                    JSON.stringify({
                        user: data.user,
                        organization: data.organization || {},
                        user_id: data.user_id,
                        token: data.token
                    }),
                );
                setUser(data.user);
                // Redirect to the desired dashboard or page
                //navigate(`/dashboard/${quoteId}`); // Adjust based on your actual route needs
                navigate(`/dashboard`); // Adjust based on your actual route needs
            } else {
                // Handle errors or invalid access
                navigate('/login', { replace: true });
            }
        });
    }, [quoteId, checkInToken, navigate]);

    // Optionally render a loading spinner or a transitional message
    return (
        <div>Loading...</div>
    );
}
function MarketingApp() {
  return (
    <div>
      <Helmet>
        <title>Carrier Connect Portal - Empowering Seamless Logistics</title>
        <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <Routes>
         <Route path='/' element={<LoginScreen />}></Route>
         <Route path="/s/:quoteId/:checkInToken" element={<SSOHandler />} />
         <Route path='/login' element={<LoginScreen />}></Route>
         <Route path='/signup' element={<SignUp />}></Route>
         <Route path='/dashboard' element={<LoginScreen/>}></Route>
         <Route path='/track' element={<LoginScreen/>}></Route>
         <Route path='/quote' element={<LoginScreen/>}></Route>
         <Route path='/map' element={<LoginScreen/>}></Route>
         <Route path='/history' element={<LoginScreen/>}></Route>
         <Route path='/shipments' element={<LoginScreen/>}></Route>
         <Route path='/current' element={<LoginScreen/>}></Route>
         <Route path='/reports' element={<LoginScreen/>}></Route>
         <Route path='/profile' element={<LoginScreen/>}></Route>
         <Route path='/settings' element={<LoginScreen/>}></Route>
         <Route path='*' element={<Error404 />}></Route>
      </Routes>
      {/*<ScrollUpBtn />*/}
    </div>
  );
}
function App() {
   const { mode } = useMode(); // Use the mode here
   // Now you can use 'mode' to conditionally render different parts of your app
   // Example: return (mode === 'driver' ? <DriverApp /> : mode === 'dashboard' ? <DashboardApp /> : <MarketingApp />)
    const {
        snackbar, handleCloseSnackbar
    } = useGlobal();
   return (
     <DemoContextProvider>
             <Snackbar
                 open={snackbar.open}
                 autoHideDuration={6000}
                 onClose={handleCloseSnackbar}
                 anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
             >
                 <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                     {snackbar.message}
                 </Alert>
             </Snackbar>
    <div>
      <Helmet>
        <title>Carrier Connect Portal - Empowering Seamless Logistics</title>
        <link rel="shortcut icon" href="favicon.ico" type="image/x-icon" />
      </Helmet>
      <Routes>
          <Route path="/s/:quoteId/:checkInToken" element={<SSOHandler />} />
         <Route path='/test' element={<CarrierPortal/>}></Route>

         <Route path='/' element={<Dashboard/>}></Route>
         <Route path='/dashboard' element={<Dashboard/>}></Route>
          <Route path='/track' element={<TrackingDashboard/>}></Route>
          <Route path='/quote' element={<QuoteFormScreen/>}></Route>
          <Route path='/map' element={<MapDashboard/>}></Route>
          <Route path='/current' element={<CurrentShipmentsProgress/>}></Route>

          <Route path='/history' element={<ShipmentDashboard/>}></Route>
          <Route path='/shipments' element={<ShipmentDashboard/>}></Route>

          <Route path='/reports' element={<ReportDashboard/>}></Route>
          <Route path='/profile' element={<Error404/>}></Route>
          <Route path='/settings' element={<Error404/>}></Route>

         <Route path='*' element={<Error404 />}></Route>
      </Routes>
      <ToastContainer />
    </div>
     </DemoContextProvider>
  );
}
function AppProvider() {
    const { userID } = useCurrentUser();

    return (
        <GlobalProvider>
            {userID ? <App /> : <MarketingApp />}
        </GlobalProvider>
    );
}


const ThemeContainer = () => {
   const {
      state: { primary_color, secondary_color },
   } = useContext(OrganizationContext);
   return (
      <ThemeContext.Provider value={{
         darkMode: undefined,
         toggleTheme: () => {}
      }}>
         <StyledEngineProvider injectFirst>
            {/*<ThemeProvider theme={LightTheme(primary_color, secondary_color)}>*/}
            <ThemeProvider theme={theme}>

                  <UserContextProvider>
                        <AppProvider/>
                  </UserContextProvider>
            </ThemeProvider>
         </StyledEngineProvider>
      </ThemeContext.Provider>
   );
};
const AppContainer = () => {
   const queryClient = new QueryClient();
   return (
      <QueryClientProvider client={queryClient}>
         <SnackbarProvider
         anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
         variant={'info'}
         autoHideDuration={4000}
         preventDuplicate={true}
         maxSnack={6}
      >
         <OrganizationContextProvider>
            <ModeProvider>
            <ThemeContainer/>
            </ModeProvider>
         </OrganizationContextProvider>
         </SnackbarProvider>
      </QueryClientProvider>
   );
};

export default AppContainer;
