// DeliverySpeedOptions.js
import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

const DeliverySpeedOptions = () => {
   const { deliverySpeed, setDeliverySpeed } = useQuote();
   
   return (
      <FormControl component="fieldset" fullWidth>
         <FormLabel component="legend">Delivery Speed</FormLabel>
         <RadioGroup
            aria-label="delivery-speed"
            value={deliverySpeed}
            onChange={(e) => setDeliverySpeed(e.target.value)}
         >
            <FormControlLabel value="standard" control={<Radio />} label="Standard Delivery" />
            <FormControlLabel value="expedited" control={<Radio />} label="Expedited Delivery" />
         </RadioGroup>
      </FormControl>
   );
};

export default DeliverySpeedOptions;
