// VesselTypeSelect.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ExpandMore } from "@mui/icons-material";
import {useQuote} from "../../lib/contexts/QuoteContext";
const vesselOptions = [
   { value: 'sailboat', label: 'Sailboat' },
   { value: 'motorboat', label: 'Motorboat' },
   { value: 'yacht', label: 'Yacht' },
   { value: 'jet ski', label: 'Jet Ski' },
   { value: 'catamaran', label: 'Catamaran' },
   { value: 'fishing boat', label: 'Fishing Boat' },
   { value: 'pontoon boat', label: 'Pontoon Boat' },
   { value: 'speed boat', label: 'Speed Boat' },
   { value: 'cabin cruiser', label: 'Cabin Cruiser' },
   { value: 'dinghy', label: 'Dinghy' },
   { value: 'deck boat', label: 'Deck Boat' },
   { value: 'tugboat', label: 'Tugboat' },
   { value: 'racing boat', label: 'Racing Boat' },
   { value: 'houseboat', label: 'Houseboat' },
   { value: 'other', label: 'Other' }
];

const VesselTypeSelect = ({placeholder = "Vessel Type", size, value, onChange }) => {

   const { vesselType, setVesselType } = useQuote();
   return (
      <FormControl fullWidth margin="normal" size={size}>
         <InputLabel>{placeholder}</InputLabel>
         <Select
             size={size}
            value={value || vesselType}
            label={placeholder}
            onChange={(e) => onChange(e) || setVesselType(e.target.value)}
            IconComponent={ExpandMore}
         >

            {vesselOptions.map((option) => (
               <MenuItem key={option.value} value={option.value}>
                  {option.label}
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   );
};

export default VesselTypeSelect;
