import React, {useCallback, useMemo, useState} from 'react';
import {Box, Card, CardContent, Grid, Snackbar, Tab, Tabs} from '@mui/material';
import VehicleQuoteForm from './VehicleQuoteForm';
import MaterialQuoteForm from './MaterialQuoteForm';
import {Alert, LoadingButton} from "@mui/lab";
import MarineQuoteForm from "./MarineQuoteForm";
import QuoteList from "../QuoteList/QuoteList";
import {Sailing, LocalShipping, DirectionsCar} from "@mui/icons-material";
import {QuoteProvider, useQuote} from "../../lib/contexts/QuoteContext";
import OrderList from "../OrderList/OrderList";
import {useGlobal, useMode} from "../../lib/contexts";
import {postQuote} from "../../lib/api";
import useCurrentUser from "../../lib/hooks/useCurrentUser";
const transitionStyle = {
    transition: 'width 0.3s ease-in-out',
};

const QuoteForm = ({ defaultMode = 'Auto', hideMode }) => {
    const { mode } = useMode();
   const { token, userID } = useCurrentUser();
   const {
      tabValue, setTabValue,
      isPosting, setIsPosting,
      pickupLocation,
      dropoffLocation,
      vehicleType,
      setSnackbar,
      setDidSearch,
      vehicleVIN,
       quotes,
       vehicleYear,
       vehicleMake,
       vehicleModel,
       vehicleMakeModel,
       pickupDateTime,
       dropoffDateTime,
       insurance, insuranceRequired, insuranceValue, setQuotes, setVehicles
   } = useQuote();

    const handleSubmit = useCallback(async () => {
        const quoteData = {
            quote_type: 'auto',
            pickup_location: pickupLocation,
            delivery_location: dropoffLocation,
            vehicle_type: vehicleType,
            vin: vehicleVIN,
            vehicle_year: vehicleYear,
            vehicle_make: vehicleMake,
            vehicle_model: vehicleModel,
            vehicle_make_model: vehicleMakeModel?.Make_Name,
            desired_pickup_date: pickupDateTime,
            desired_delivery_date: dropoffDateTime,
        };
        setIsPosting(true);
        setSnackbar({
            open: true,
            message: "Hang tight! Compiling the best quotes based on your information.",
            severity: 'info',
        });

        const { data, error } = await postQuote(token, userID, quoteData);
        setIsPosting(false);
        if (error) {
            console.error('Failed to submit quote request:', error);
            setSnackbar({
                open: true,
                message: 'Failed to retrieve data: ' + error,
                severity: 'error',
            });
        } else {
            setDidSearch(true)
            //console.log('Quote submission successful:', data);
            //console.log('Session Vehicles:', data[0]['vehicles']);
            setQuotes(data)
            setVehicles(data[0]['vehicles'])
        }
    }, [token, userID, pickupLocation, dropoffLocation, vehicleType, vehicleVIN, vehicleYear, vehicleMake, vehicleModel, vehicleMakeModel]);


    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
   };

    const validateForm = useMemo((isValid = false) => {
        switch(tabValue) {
            case 0:
                isValid = !(!pickupLocation || !dropoffLocation || !vehicleVIN || !vehicleType || !vehicleYear || !vehicleMakeModel);
                break;
            case 1:
                isValid = false;
                break;
            case 2:
                isValid = false;
                break;
            case 3:
                isValid = false;
                break;
            default:
                isValid = false;
                break;
        }
        return isValid;
    }, [vehicleVIN, pickupLocation, dropoffLocation, vehicleType, tabValue, vehicleYear, vehicleMakeModel]);


    return (
         <Box sx={{ p: 2 }}>
             <Card sx={transitionStyle}>
                 <CardContent>
                     <Grid container spacing={2}>
                         <Grid item xs={12} md={quotes.length > 0 ? 6 : 12} sx={transitionStyle}>
                             <Tabs
                                 value={tabValue}
                                 onChange={handleTabChange}
                                 aria-label="quote type tabs"
                                 variant="fullWidth"
                                 sx={{ mb: 2 }}
                             >
                                 {!hideMode || hideMode === 'Material' ? (
                                     <Tab label="Auto Transport" icon={<DirectionsCar/>} />
                                 ) : null}
                                 {mode === 'admin' && (!hideMode || hideMode === 'Marine') ? (
                                     <Tab label="Marine" icon={<Sailing/>}/>
                                 ) : null}
                                 {mode === 'admin' && (!hideMode || hideMode === 'Auto') ? (
                                     <Tab label="Materials" icon={<LocalShipping/>}/>
                                 ) : null}
                             </Tabs>

                             {tabValue === 0 && <VehicleQuoteForm />}
                             {tabValue === 1 && <MaterialQuoteForm />}
                             {tabValue === 2 && <MarineQuoteForm />}

                             <Box textAlign="center" marginTop={2}>
                                 <LoadingButton
                                     onClick={handleSubmit}
                                     variant="contained"
                                     color="primary"
                                     loading={isPosting}
                                     disabled={isPosting || !validateForm}
                                 >
                                     Get Quote
                                 </LoadingButton>
                             </Box>


                         </Grid>

                         <Grid item xs={12} md={Boolean(quotes.length) ? 6 : 12} sx={transitionStyle}>
                             <QuoteList mode={tabValue === 0 ? 'auto' : tabValue === 1 ? 'material' : 'marine'} />
                         </Grid>
                     </Grid>
                 </CardContent>
             </Card>
         </Box>
   );
};

function QuoteSnackbarWrapper() {
   return (
      <>
         <QuoteForm onSubmit={() => {}} />
      </>
   );
}
function QuoteWrapper() {
   return (
      <QuoteProvider defaultMode={'Auto'} hideMode={''}>
         <QuoteSnackbarWrapper/>
      </QuoteProvider>
   );
}
export default QuoteWrapper;
