// RouteInfo.js
import React, {useMemo, useState} from 'react';
import {
    Box,
    Typography,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    List,
    ListItemIcon,
    Button,
    Grid, Chip
} from '@mui/material';
import {CalendarToday, DirectionsBoat, DriveEta, Info, Layers, LocalShipping, Place} from "@mui/icons-material";
import PhoneIcon from "@mui/icons-material/Phone";
import useAlerts from "../../lib/hooks/useAlerts";
import useSummary from "../../lib/hooks/useSummary";
import RefreshButton from "../RefreshButton/RefreshButton";
import {DriverCheckinDialog} from "../DriverCheckinDialog/DriverCheckinDialog";
import {formatMoney, prettyDateTime, toCapitalizeFromUnderScore} from "../../lib/helpers/helpers";

// Example shipment data structure
const example_shipment = {
    id: '123456789',
    type: 'auto', // Could be 'auto', 'material', or 'marine'
    details: {
        vehicle: { make: 'Toyota', model: 'Camry', year: 2022 }, // For auto type
        materialType: 'Steel', // For material type
        totalWeight: 5000, // For material type
        vesselType: 'Cargo Ship', // For marine type
        vesselName: 'SS Minnow', // For marine type
    },
    status: 'In Transit',
    pickup: { location: 'San Francisco, CA', date: '2024-03-25' },
    dropoff: { location: 'New York, NY', date: '2024-04-10' },
    additionalInfo: 'Handle with care.',
    expectedDelivery: '2024-04-10',
};

const renderDetailsByType = () => {
    switch (shipment?.type) {
        case 'auto':
            return (
                <>
                    <Typography variant="body2">
                        Vehicle: {shipment.details.vehicle.make} {shipment.details.vehicle.model} ({shipment.details.vehicle.year})
                    </Typography>
                </>
            );
        case 'material':
            return (
                <>
                    <Typography variant="body2">
                        Material Type: {shipment.details.materialType}
                    </Typography>
                    <Typography variant="body2">
                        Total Weight: {shipment.details.totalWeight} lbs
                    </Typography>
                </>
            );
        case 'marine':
            return (
                <>
                    <Typography variant="body2">
                        Vessel Type: {shipment.details.vesselType}
                    </Typography>
                    {shipment.details.vesselName && (
                        <Typography variant="body2">
                            Vessel Name: {shipment.details.vesselName}
                        </Typography>
                    )}
                </>
            );
        default:
            return null;
    }
};

const RouteInfo = ({layout = null, mode, overRideQuoteOrder = null, quantityMultiple = 1, showTitle = true, quoteMode = false}) => {
    if (quantityMultiple === 0) {
        quantityMultiple = 1;
    }
    const { data, isLoading, isRefetching, refetch } = useSummary()
    const shipment = useMemo(() => overRideQuoteOrder || data?.route_info, [data, overRideQuoteOrder])
    const shipmentPrice = useMemo(() => {
        return (shipment?.price || 0)
    }, [shipment]);
    const shipmentPriceMultiplied = useMemo(() => {
        return (shipmentPrice * (quantityMultiple || 0))
    }, [shipmentPrice, quantityMultiple]);
    const depositDue = useMemo(() => {
        return formatMoney((shipmentPriceMultiplied * (shipment?.deposit_percentage || 0)));
    }, [shipment, shipmentPriceMultiplied]);

    const [openCheckin, setOpenCheckin] = useState(false)
    // Function to dynamically return the icon based on the shipment type
    const getIconForType = (type) => {
        switch (type) {
            case 'auto':
                return <DriveEta />;
            case 'material':
                return <Layers />;
            case 'marine':
                return <DirectionsBoat />;
            default:
                return <Info />;
        }
    };
    const multiplePrice = quantityMultiple > 1
        ? (
            <span>
            <span title={formatMoney(shipmentPrice) + ' per VIN'}>
                <b>{formatMoney(shipmentPriceMultiplied)}</b> (for {quantityMultiple} VINs) |
            </span> {shipment?.deposit_percentage && <>
                <span style={{ color: 'red' }} title={`A deposit of ${shipment.deposit_percentage * 100}% is required.`}>
                <b>{depositDue}</b> Due Now
            </span></>}
            </span>
        )
        : <span>
                <b>{formatMoney(shipmentPrice)}</b> {shipment?.deposit_percentage && <>
            <span>
                <b>{depositDue}</b> Due Now
            </span></>}
            </span>;

    // Function to dynamically return the details based on the shipment type
    const getDetailsForType = (shipment) => {
        switch (shipment?.quote_type || shipment?.type) {
            case 'shipment':
            case 'auto':
                // First, check if shipment.vehicle_make_model is available
                if (shipment.vehicle_make_model) {
                    return shipment.vehicle_make_model;
                }
                // Then check for detailed vehicle information
                return shipment?.details?.vehicle?.make && shipment?.details?.vehicle?.model && shipment?.details?.vehicle?.year
                    ? `${shipment.details.vehicle.make} ${shipment.details.vehicle.model} (${shipment.details.vehicle.year})`
                    : "Auto";
            case 'material':
                return `${shipment?.details?.materialType}, Total Weight: ${shipment?.details?.totalWeight} lbs`;
            case 'marine':
                return `Vessel Type: ${shipment?.details?.vesselType}, Name: ${shipment?.details?.vesselName}`;
            default:
                return 'N/A';
        }
    };


    const pickupAddress = useMemo(() => {
        const locationInfo = Array.isArray(shipment?.locations) ? shipment.locations.find(location => location?.type === 'pickup') : null;
        return locationInfo?.address || shipment?.pickup?.location?.address || 'No pickup address provided';
    }, [shipment]);

    const dropoffAddress = useMemo(() => {
        const locationInfo = Array.isArray(shipment?.locations) ? shipment.locations.find(location => location?.type === 'dropoff') : null;
        return locationInfo?.address || shipment?.dropoff?.location?.address || 'No dropoff address provided';
    }, [shipment]);
    return (
        <Card style={{marginTop: quoteMode ? 0 : 30}}>
            <CardContent>
                {showTitle && <Typography variant="h6" gutterBottom>
                    {layout !== 'list' && <>
                        {mode === 'driver' ? 'Active Job' : 'Shipment Details'}
                        {mode === 'driver' && <RefreshButton onRefresh={refetch} isLoading={isLoading || isRefetching}/>}
                    </>}
                    {(mode === 'admin' || mode === 'dashboard') && <>
                        <Chip label={toCapitalizeFromUnderScore(shipment?.status)} color="success" variant="outlined" />
                        <Button
                            style={{marginLeft:20}}
                            size={'small'}
                            variant="outlined"
                            onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(pickupAddress)}&destination=${encodeURIComponent(dropoffAddress)}&travelmode=driving`, '_blank')}
                        >View Route</Button>
                    </>}


                </Typography>}
                {mode === 'driver' && <><List>
                    <ListItem>
                        <Button startIcon={<Place/>} variant="outlined" fullWidth={true} onClick={() => setOpenCheckin(true)}>
                            Check-In
                        </Button>
                    </ListItem>
                </List>
                    {mode === 'driver' && <DriverCheckinDialog isOpen={openCheckin}
                                          onClose={() => setOpenCheckin(false)}
                    />}
                </>}
                <Grid container item xs={12} >
                <Grid item xs={6}>
                    <List dense={quoteMode}>
                        <ListItem>
                            <ListItemIcon>
                                <LocalShipping />
                            </ListItemIcon>
                            <ListItemText primary={quoteMode ? "Estimated Price" : overRideQuoteOrder ? "Quote Number" : "Order Number"} secondary={quoteMode ? multiplePrice : shipment?.id} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Place />
                            </ListItemIcon>
                            <ListItemText primary="Pick-up Location"
                                          secondary={<>
                                              {`${pickupAddress}`}
                                              <br/>
                                              {`${prettyDateTime(shipment?.actual_pickup_date_time || shipment?.pickup?.date)}`}
                                          </>}/>
                        </ListItem>
                        {shipment?.expectedDelivery && <ListItem>
                            <ListItemIcon>
                                <CalendarToday/>
                            </ListItemIcon>
                            <ListItemText primary="Expected Delivery"
                                          secondary={prettyDateTime(shipment?.expectedDelivery)}/>
                        </ListItem>}
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                {getIconForType(shipment?.type)}
                            </ListItemIcon>
                            <ListItemText primary="Type" secondary={getDetailsForType(shipment)} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Place />
                            </ListItemIcon>
                            <ListItemText primary="Drop-off Location" secondary={<>
                            {`${dropoffAddress}`}
                                <br/>
                            {`${prettyDateTime(shipment?.actual_delivery_date_time || shipment?.dropoff?.date)}`}
                            </>} />
                        </ListItem>
                        {shipment?.additionalInfo && (
                            <ListItem>
                                <ListItemIcon>
                                    <Info />
                                </ListItemIcon>
                                <ListItemText primary="Additional Information"
                                              secondary={
                                                  <ul>
                                                      {shipment?.additionalInfo?.map((info, index) => (
                                                          <li key={index}>{info}</li>
                                                      ))}
                                                  </ul>
                                              } />
                            </ListItem>
                        )}
                    </List>
                </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default RouteInfo;
