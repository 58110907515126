// MaterialQuoteForm.js
import React from 'react';
import {Stack} from "@mui/material";
import LocationAutocomplete from "../LocationAutocomplete/LocationAutocomplete";
import {useQuote} from "../../lib/contexts/QuoteContext";
import VesselTypeSelect from "./VesselTypeSelect";
import DimensionsFields from "./DimensionsFields";
import AdditionalServiceAndPreferences from "./AdditionalServiceAndPreferences";
import VehicleVIN from "./VehicleVIN";

const MarineQuoteForm = () => {
   const {
      pickupLocation, setPickupLocation,
      dropoffLocation, setDropoffLocation
   } = useQuote();
   return (
      <Stack direction="column" spacing={2}>
         <LocationAutocomplete
            label="Pickup Location"
            value={pickupLocation?.formatted_address || ''}
            onPlaceSelected={setPickupLocation}
         />
         <LocationAutocomplete
            label="Dropoff Location"
            value={dropoffLocation?.formatted_address || ''}
            onPlaceSelected={setDropoffLocation}
         />
         <VehicleVIN mode={"marine"}  />
         <VesselTypeSelect />
         <DimensionsFields mode={'marine'}/>
         <AdditionalServiceAndPreferences mode={'marine'} />
      </Stack>
   );
};

export default MarineQuoteForm;
