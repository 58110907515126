import Screen from "../../components/Screen/Screen";
import React from "react";
import QuoteForm from "../../components/QuoteForm/QuoteForm";

const QuoteFormScreen = () => {
   return (
      <>
         <Screen title={'New Quote'}>
            <QuoteForm />
         </Screen>
      </>
   )
}
export default QuoteFormScreen
