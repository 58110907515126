import { useQuery } from 'react-query';
import {getAlerts, getSummary} from "../api";
import useCurrentUser from "./useCurrentUser";

const useAlerts = () => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('alerts_', () => getAlerts(token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useAlerts;
