import React, { useState, useEffect } from 'react';
import { FormControl, TextField } from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

const TransportID = ({ mode = 'auto', size, value, onChange = () => {}, viewMode = 'last_vin', error = false }) => {
   const { vehicleVIN, setVehicleVIN } = useQuote(); // Assuming vehicleVIN is used generically for both VIN and HIN
   const [internalError, setError] = useState();

   // Validation rules might differ slightly between VIN and HIN, adjust as necessary
   const isValidCharacter = (id) => /^[A-HJ-NPR-Z0-9]*$/.test(id);
   const expectedLength = mode === 'auto' ? 17 : 12; // VINs are 17 characters, HINs are 12 characters

   useEffect(() => {
      if (vehicleVIN.length > 0 && vehicleVIN.length < expectedLength) {
         setError(`A ${mode === 'auto' ? 'VIN' : 'HIN'} must be exactly ${expectedLength} characters long.`);
      } else if (vehicleVIN.length === expectedLength && !isValidCharacter(vehicleVIN)) {
         setError(`Invalid ${mode === 'auto' ? 'VIN' : 'HIN'}. Ensure it does not contain I, O, or Q.`);
      } else {
         setError('');
      }
   }, [vehicleVIN, mode, expectedLength]);

   const handleChange = (e) => {
      const id = e.target.value.toUpperCase(); // Convert to uppercase for standard format
      if (id.length <= expectedLength) {
         if (isValidCharacter(id)) {
            setVehicleVIN(id);
            onChange({
               target: {
                  value: id // Assign the processed ID to the value property
               }
            });
         } else {
            setError(`Invalid character. A ${mode === 'auto' ? 'VIN' : 'HIN'} does not contain I, O, or Q.`);
         }
      } else {
         setError(`A ${mode === 'auto' ? 'VIN' : 'HIN'} must be exactly ${expectedLength} characters.`);
      }
   };

   return (
      <FormControl fullWidth margin="normal" error={error || !!internalError}
                   size={size}>
         <TextField
             size={size}
            label={mode === 'auto' ? size === 'small' ? 'VIN' : "Vehicle VIN" : size === 'small' ? 'HIN' : "Boat HIN"}
            placeholder={mode === 'auto' ? size === 'small' ? 'VIN' : "Vehicle VIN" : size === 'small' ? 'HIN' : "Boat HIN"}
            variant="outlined"
            value={value || viewMode !='new_vin' ? vehicleVIN : ''}
            onChange={handleChange}
            error={error || !!internalError}
             helperText={
                 size !== 'small' && (internalError || `Enter the ${mode === 'auto' ? 'Vehicle Identification Number' : 'Hull Identification Number'} (up to ${expectedLength} characters)`)
             }
             inputProps={{ maxLength: expectedLength }}

         />
      </FormControl>
   );
};

export default TransportID;
