// QuoteList.js
import React from 'react';
import {useQuote} from "../../lib/contexts/QuoteContext";
import { List, Divider, Typography, CircularProgress, Box } from '@mui/material';
import QuoteItemDetail from "../QuoteItemDetail/QuoteItemDetail";

const QuoteList = ({mode}) => {
   const { quotesError, isPosting, quotes, didSearch } = useQuote(); // quotes,



   if (isPosting) {
      return (
         <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
            <CircularProgress />
         </Box>
      );
   }

   if (quotesError) {
      return <Typography variant="h6" align="center" color="error">Failed to load quotes: {quotesError.message}</Typography>;
   }

   if (quotes.length === 0) {
      return <Typography variant="h6" align="center">{didSearch ? "No quotes available" : ""}</Typography>;
   }

   return (
      <List style={{ maxHeight: 520, overflowY: 'auto' }}>
         {quotes.map((quote) => (
            <React.Fragment key={quote.id}>
               <QuoteItemDetail quote={quote} mode={mode} />
               <Divider variant="inset" component="li" />
            </React.Fragment>
         ))}
      </List>
   );
};

export default QuoteList;
