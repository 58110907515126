import React from 'react';
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormGroup} from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

const transportOptions = [
   "20ft Container", "40ft Container", "40ft HC (High Cube) Container", "45ft HC (High Cube) Container",
   "20ft Reefer Container", "40ft Reefer Container", "40ft HC Reefer Container", "45ft HC Reefer Container",
   "20ft Open Top Container", "40ft Open Top Container", "20ft Flat Rack", "40ft Flat Rack",
   "20ft Tank Container", "40ft Tank Container", "20ft Bulk Container", "40ft Bulk Container",
   "20ft Ventilated Container", "40ft Ventilated Container", "20ft Platform", "40ft Platform",
   "20ft Collapsible Flat Rack", "40ft Collapsible Flat Rack", "20ft Garmentainer", "40ft Garmentainer",
   "Enclosed Auto Transport", "Open Auto Transport", "Specialized Boat Transport",
   "Hazmat Certified Transport", "Temperature Controlled Transport", "Oversized Load Transport"
].map(value => ({ value, label: value.replace(/([A-Z])/g, ' $1').trim().replace(/ft/g, ' ft').replace(/HC/g, 'HC (High Cube)') }));

const TypeOfTransport = () => {
   const { transportType, setTransportType } = useQuote();
   
   return (
      <FormControl component="fieldset" fullWidth>
         <FormLabel component="legend">Type of Transport</FormLabel>
         <FormGroup sx={{ maxHeight: '200px', overflowY: 'auto' }}>
         <RadioGroup
            aria-label="type-of-transport"
            value={transportType}
            onChange={(e) => setTransportType(e.target.value)}
            name="transport-type"
         >
            {transportOptions.map(option => (
               <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
            ))}
         </RadioGroup>
         </FormGroup>
      </FormControl>
   );
};

export default TypeOfTransport;
