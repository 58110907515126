import React, {useContext} from 'react';
import Header from '../Header';
import BreadCums from '../BreadCums';
import {Grid} from "@mui/material";
import PlanDetails from "./PlanDetails";
import FAQManagementList from "../FAQManagementScreen/FAQManagementList";
import ChartDetails from "./ChartDetails";
import ActivityDetails from "./ActivityDetails";
import {DemoContext} from "../../lib/contexts/DemoContext";
import MetricCard from "../MetricCard/MetricCard";
import DashboardAlerts from "./DashboardAlerts";
import CurrentShipmentsProgress from "./CurrentShipmentsProgress";
import CurrentDrivers from "./CurrentDrivers";
import RecentMedia from "./RecentMedia";
import RecentReviews from "./RecentReviews";
import LogisticsMap from "./LogisticsMap";
import CurrentTransports from "./CurrentTransports";
import {useMode} from "../../lib/contexts";
import QuoteForm from "../QuoteForm/QuoteForm";
import RouteMap from "./RouteMap";
import RouteInfo from "./RouteInfo";
import CreateReview from "../CreateReview/CreateReview";
import DriverSummary from "./DriverSummary";
import LatestDriverCheckins from "./LatestDriverCheckins";

const Dashboard = () => {
   const {
      state: { matchSM, matchMD },
   } = useContext(DemoContext);
    const {mode} =  useMode();

    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Dashboard' empty />
           <section className={`${matchSM ? '' :'sc-feature-section-area5'} sc-pt-30 sc-md-pt-30 sc-pb-140 sc-md-pb-70`}>
              <div className="container">
                 <div className="row align-items-center">
                     <Grid container item xs={12} spacing={matchSM ? 1 : 3} alignItems="flex-start" style={{ margin: 'auto' }}>

                         <Grid container item xs={12} spacing={2} justifyContent="space-between">
                             {mode === 'admin' ? <>
                                     <MetricCard title={"Today's Shipments"} slug={'today_shipment'}/>
                                     <MetricCard title={'Shipments in Progress'} slug={'shipments_in_progress'}/>
                                     <MetricCard title={'Total Shipments'} slug={'total_shipment'}/>
                                     <MetricCard title={'Avg Delivery Time'} slug={'avg_delivery_time'}/>
                                     {/*<MetricCard title={"Today's Revenue"} slug={'today_revenue'} />*/}
                                     {/*<MetricCard title={'Issues Reported'} slug={'issues_reported'}/>
                                     <MetricCard title={'Driver Performance'} slug={'driver_performance'}/>
                                     <MetricCard title={'Customer Feedback'} slug={'customer_feedback'}/>*/}
                                 </> :
                                 mode === 'dashboard' ? <>
                                         <MetricCard title={"Today's Shipments"} slug={'today_shipment'}/>
                                         <MetricCard title={'Shipments in Progress'} slug={'shipments_in_progress'}/>
                                         <MetricCard title={'Total Shipments'} slug={'total_shipment'}/>
                                         <MetricCard title={'Avg Delivery Time'} slug={'avg_delivery_time'}/>
                                     </> :
                                     mode === 'driver' ? <>
                                       {/*  <MetricCard title={'Total Shipments'} slug={'total_shipment'} />
                                         <MetricCard title={'Avg Delivery Time'} slug={'avg_delivery_time'} />
                                             <MetricCard title={"Total Revenue"} slug={'total_revenue'} />*/}
                                         </> :
                                         <></>
                             }

                        </Grid>

                         {mode === 'admin' ? <>
                                 <Grid item xs={matchSM ? 12 : 6}>
                                     <DashboardAlerts/>
                                 </Grid>
                                 <Grid item xs={matchSM ? 12 : 6}>
                                     <LogisticsMap/>
                                 </Grid>
                                 <Grid item xs={matchSM ? 12 : 6}>
                                     <CurrentTransports/>
                                 </Grid>
                                 <Grid item xs={matchSM ? 12 : 6}>
                                     <LatestDriverCheckins/>
                                 </Grid>
                                 <Grid item xs={matchSM ? 12 : 6}>
                                     <RecentReviews mode={mode}/>
                                 </Grid>
                                 <Grid item xs={matchSM ? 12 : 6}>
                                     <RecentMedia/>
                                 </Grid>
                             </> :
                             mode === 'dashboard' ? <>
                                     <Grid item xs={matchSM ? 12 : 8}>
                                         <QuoteForm/>
                                     </Grid>
                                     <Grid item xs={matchSM ? 12 : 4}>
                                         <CurrentTransports/>
                                         <br/>
                                         <LatestDriverCheckins/>
                                     </Grid>
                                     <Grid item xs={matchSM ? 12 : 6}>
                                         <LogisticsMap/>
                                     </Grid>
                                     <Grid item xs={matchSM ? 12 : 6}>
                                         <RecentMedia/>
                                     </Grid>
                                 </> :
                                 mode === 'driver' ? <>
                                         <Grid item xs={matchSM ? 12 : 6}>
                                             <RouteInfo mode={mode}/>
                                         </Grid>
                                         <Grid item xs={matchSM ? 12 : 6}>
                                             <RouteMap/>
                                         </Grid>
                                         <Grid item xs={matchSM ? 12 : 6}>
                                             <DriverSummary mode={mode}/>
                                         </Grid>
                                         <Grid item xs={matchSM ? 12 : 6}>
                                             <RecentReviews mode={mode}/>
                                         </Grid>
                                     </> :
                                     <>
                                     {/* track */}

                                         <Grid item xs={matchSM ? 12 : 6}>
                                             <RouteInfo mode={mode}/>
                                         </Grid>
                                         <Grid item xs={matchSM ? 12 : 6}>
                                             <RouteMap/>
                                         </Grid>
                                         <Grid item xs={matchSM ? 12 : 6}>
                                             <DriverSummary mode={'track'}/>
                                         </Grid>
                                         <Grid item xs={matchSM ? 12 : 6}>
                                             <CreateReview/>
                                         </Grid>
                                     </>
                         }


                    </Grid>

                     {/* <MetricCard title={'Number of VINs moved'}/>
                          <MetricCard title={'Number of BINs moved'}/>
                          <MetricCard title={'Pounds of Material moved'}/>
                          <BillingOverview/>
                          <FleetUtilization/>



                       <Grid item xs={matchSM ? 12 : 4}>
                          <PlanDetails/>
                       </Grid>
                       <Grid item xs={matchSM ? 12 : 8}>
                          <FAQManagementList summary />
                       </Grid>
                       <Grid item xs={matchSM ? 12 : matchMD ? 7 : 8}>
                          <ChartDetails/>
                       </Grid>
                       <Grid item xs={matchSM ? 12 : matchMD ? 5 : 4}>
                          <ActivityDetails/>
                       </Grid>

                          */}
                     {/*<Grid item xs={matchSM ? 12 : 3}>
                            <CurrentShipmentsProgress/>
                        </Grid>
                        <Grid item xs={matchSM ? 12 : 3}>
                            <CurrentDrivers/>
                        </Grid>*/}
                 </div>
              </div>
           </section>
        </>
    );
};

export default Dashboard;
