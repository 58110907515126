import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getMedia = async (mediaId, token, userID) => {
  const apiURL =  API_HOST + (mediaId ? '/media/' + mediaId : '/media');
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
