import React from 'react';
import Header from '../../components/Header';
import BreadCums from '../../components/BreadCums';
import SignUpForm from './SignUpForm';
import Apps from '../../components/Apps';
import Footer3 from '../../components/Footer3';

const SignUp = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='Sign Up' />
            <SignUpForm />
            <Apps />
            <Footer3 />
        </>
    );
};

export default SignUp;
