import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const OffCanvasMenu = ({ navItems = [], open = false, setMenuOpen = () => {} }) => {
    const location = useLocation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Drawer
            anchor="right"
            open={open}
            variant={fullScreen ? 'temporary' : 'persistent'}
            onClose={() => setMenuOpen(!open)}
            sx={{ '& .MuiDrawer-paper': { width: 250, boxSizing: 'border-box' } }}
        >
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton
                            component={Link}
                            to={item.path || '#'}
                            onClick={item.onClick}
                            selected={location.pathname === item.path}
                        >
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>
    );
};

export default OffCanvasMenu;
