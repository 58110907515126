import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

const BreadCums = (props) => {
    const { pageTitle, empty, left, short } = props;
    return (
        <>
            <div className={empty || short ? "sc-breadcrumbs-mini" : "sc-breadcrumbs"}>
                <div className="container">
                    <div className="breadcrumbs-text white-color">
                        <h1 className={(short ? "page-title-short sc-mt-20" : "page-title") + " sc-sm-pt-20"} style={{textAlign:left ? "left" : "center"}}>{!empty ? pageTitle : ''}</h1>
                        <ul className={"d-none"}>
                            <li><Link className="active" to="/#">Home</Link></li>
                            <li>{pageTitle ? pageTitle : ""}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BreadCums;
