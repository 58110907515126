// MediaModal.js

import React from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton, List, ListItem, ListItemText,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const MediaModal = ({ open, handleClose, media }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="media-modal-title"
            aria-describedby="media-modal-description"
        >
            <Box sx={style}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {media?.type === 'image' ? (
                    <img src={media.src} alt="" style={{ maxWidth: '100%', height: 'auto' }} />
                ) : (
                    <video controls src={media.src} style={{ maxWidth: '100%', height: 'auto' }}>
                        Your browser does not support the video tag.
                    </video>
                )}

                <List style={{overflowY:'auto', maxHeight:320}}>
                    {Object.entries(media.associated_details || {}).map(([key, value], idx) => (
                        <ListItem key={idx}>
                            <ListItemText primary={key.replace(/_/g, ' ').toUpperCase()} secondary={value} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Modal>
    );
};

export default MediaModal;
