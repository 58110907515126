import Screen from "../../components/Screen/Screen";
import React from "react";
import LogisticsMap from "../../components/Dashboard/LogisticsMap";

const MapDashboard = () => {
   return (
      <>
         <Screen title={'Map Dashboard'}>
            <LogisticsMap fullSize={true}/>
         </Screen>
      </>
   )
}
export default MapDashboard
