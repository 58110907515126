import React, { useRef, useEffect, useState } from 'react';
import {TextField} from '@mui/material';
import loadGoogleMapsApi from 'load-google-maps-api';
import {GOOGLE_API_kEY} from "../../lib/constants/constants";

const LocationAutocomplete = ({ label, onPlaceSelected, value }) => {
   const inputRef = useRef(null);
   const [inputValue, setInputValue] = useState(value?.formatted_address || '');
   const [formattedAddress, setFormattedAddress] = useState(value?.formatted_address || '');
   const [shouldLoadAutocomplete, setShouldLoadAutocomplete] = useState(false);


   useEffect(() => {
      if (shouldLoadAutocomplete) {
         loadGoogleMapsApi({
            key: GOOGLE_API_kEY,
            libraries: ['places'],
         }).then((googleMaps) => {
            const autocompleteInstance = new googleMaps.places.Autocomplete(inputRef.current, {
               types: ['geocode'],
            });
            autocompleteInstance.addListener('place_changed', () => {
               const place = autocompleteInstance.getPlace();
               if (place.geometry) {
                  setFormattedAddress(place.formatted_address);
                  onPlaceSelected(place);
                  setInputValue(place.formatted_address);
               }
            });
         }).catch(error => {
            console.error("Error loading Google Maps:", error);
         });
      }
   }, [shouldLoadAutocomplete, onPlaceSelected]);
   const handleInputChange = (e) => {
      const value = e.target.value;
      setInputValue(value);

      if (value.includes(' ') && /\d/.test(value.split(' ')[0])) {
         if (!shouldLoadAutocomplete) {
            setShouldLoadAutocomplete(true);
         }
      } else {
         if (shouldLoadAutocomplete) {
            setShouldLoadAutocomplete(false);
         }
      }
   };
   return (
      <>
         <TextField
            fullWidth
            label={label}
            value={inputValue || formattedAddress}
            onChange={handleInputChange}
            margin="normal"
            inputRef={inputRef}
            helperText={inputValue && !shouldLoadAutocomplete ? 'Please enter a street number followed by a space.' : ''}
         />
      </>
   );
};

export default LocationAutocomplete;
