// DateTimeInput.js
import React from 'react';
import { TextField } from '@mui/material';

const DateTimeInput = ({ id, label, value, error, onChange, disabled, minDateTime }) => {
    return (
        <TextField
            margin="dense"
            error={error}
            id={id}
            label={label}
            type="datetime-local"
            value={value}
            onChange={onChange}
            disabled={disabled}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                min: minDateTime
            }}
            fullWidth
        />
    );
};

export default DateTimeInput;
