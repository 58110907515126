import QuoteForm from "../../components/QuoteForm/QuoteForm";
import Screen from "../../components/Screen/Screen";
import React from "react";

const CarrierPortal = () => {
   return (
      <>
         <Screen title={'Instant Quote'}>
            <QuoteForm/>
         </Screen>
      </>
   )
}
export default CarrierPortal
