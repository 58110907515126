import axios from "axios";
import {API_HOST} from "../constants/constants";

export const postDriverReview = async (token, userID, object = {}) => {
  if (!token || !userID) {
    return { data: null, error: 'Token or UserID missing' };
  }
  const apiURL = API_HOST + '/reviews';

  try {
    const response = await axios.post(apiURL, { review: object }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      }
    });
    return { data: response.data, error: null };
  } catch (error) {
    console.error('Error posting quote:', error);
    return { data: null, error: error.response?.data || 'An error occurred' };
  }
};
