// EmailInput.js
import React from 'react';
import TextField from '@mui/material/TextField';

function EmailInput({ label, id, value, onChange, disabled }) {
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');

    const handleEmailChange = (e) => {
        const value = e.target.value;
        // Simple email validation check
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (value === '' || regex.test(value)) {
            setError(false);
            setHelperText('');
        } else {
            setError(true);
            setHelperText('Invalid email address');
        }
        onChange(e);  // Pass the event to the parent component's handler
    };

    return (
        <TextField
            margin="dense"
            id={id}
            label={label}
            type="email"
            fullWidth
            variant="outlined"
            value={value}
            onChange={handleEmailChange}
            disabled={disabled}
            error={error}
            helperText={helperText}
        />
    );
}

export default EmailInput;
