import { useQuery } from 'react-query';
import useCurrentUser from "./useCurrentUser";
import {getDriverCheckins} from "../api";

const useDriverCheckins = (orderID= '') => {
  const { token, userID } = useCurrentUser();
  const result = useQuery('orders_checkins_'+ orderID, () => getDriverCheckins(orderID, token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000///50000,
  });
  return result;
};

export default useDriverCheckins;
