// VehicleTypeSelect.js
import React, {useMemo, useState} from 'react';
import {FormControl, InputLabel, Select, MenuItem, Autocomplete, TextField, Grid} from '@mui/material';
import {ExpandMore} from "@mui/icons-material";
import {useQuote} from "../../lib/contexts/QuoteContext";
import all_vehicle_make_models from "../../lib/constants/all_vehicle_make_models.json";
import {toUnderScoreSlug} from "../../lib/helpers/helpers";

const NHTSAVehicleOptions = [
   'Bus',
   'Incomplete Vehicle',
   'Low Speed Vehicle (LSV)',
   'Motorcycle',
   'Multipurpose Passenger Vehicle (MPV)',
   'Passenger Car',
   'Trailer',
   'Truck'
]

const vehicleOptions = [
   { value: 'sedan', label: 'Sedan' },
   { value: 'SUV', label: 'SUV' },
   { value: 'pickup truck', label: 'Pickup Truck' },
   { value: 'van', label: 'Van' },
   { value: 'convertible', label: 'Convertible' },
   { value: 'coupe', label: 'Coupe' },
   { value: 'sports car', label: 'Sports Car' },
   { value: 'station wagon', label: 'Station Wagon' },
   { value: 'hatchback', label: 'Hatchback' },
   { value: 'motorcycle', label: 'Motorcycle' },
   { value: 'electric vehicle', label: 'Electric Vehicle' },
   { value: 'hybrid vehicle', label: 'Hybrid Vehicle' },
   { value: 'luxury car', label: 'Luxury Car' },
   { value: 'commercial vehicle', label: 'Commercial Vehicle' },
   { value: 'RV', label: 'Recreational Vehicle (RV)' },
   { value: 'trailer', label: 'Trailer' },
   { value: 'other', label: 'Other' }
];
const vehicleOptionsMap = {
   'Bus': 'commercial vehicle',
   'Incomplete Vehicle': 'other',
   'Low Speed Vehicle (LSV)': 'electric vehicle',
   'Motorcycle': 'motorcycle',
   'Multipurpose Passenger Vehicle (MPV)': 'SUV',
   'Passenger Car': 'sedan',
   'Trailer': 'trailer',
   'Truck': 'pickup truck'
};

const sdVehicleOptions_FULL = [
    {"value": "sedan", "label": "Sedan"},
    {"value": "2_door_coupe", "label": "Coupe (2 doors)"}, // not working
    {"value": "suv", "label": "SUV"},
    {"value": "pickup", "label": "Pickup (2 doors)"}, // not working
    {"value": "4_door_pickup", "label": "Pickup (4 doors)"}, // not working
    {"value": "van", "label": "Van"},
    {"value": "truck_daycab", "label": "Truck (daycab)"}, // not working
    {"value": "truck_sleeper", "label": "Truck (with sleeper)"}, // not working
    {"value": "motorcycle", "label": "Motorcycle"}, // not working
    {"value": "boat", "label": "Boat"}, // not working
    {"value": "rv", "label": "RV"}, // not working
    {"value": "heavy_machinery", "label": "Heavy Machinery"}, // not working
    {"value": "freight", "label": "Freight"}, // not working
    {"value": "livestock", "label": "Livestock"}, // not working
    {"value": "atv", "label": "ATV"}, // not working
    {"value": "trailer_bumper_pull", "label": "Trailer (Bumper Pull)"}, // not working
    {"value": "trailer_gooseneck", "label": "Trailer (Gooseneck)"}, // not working
    {"value": "trailer_5th_wheel", "label": "Trailer (5th Wheel)"}, // not working
    {"value": "other", "label": "Other"} // not working
]

const sdVehicleOptions = [
    {"value": "sedan", "label": "Sedan"},
    {"value": "suv", "label": "SUV"},
    {"value": "van", "label": "Van"},
]


const VehicleTypeSelectIDK = ({outputMode = 'grid', placeholder = "Vehicle Type", size, value, onChange }) => {
   const { vehicleType, setVehicleType,
      vehicleYear, setVehicleYear,
      vehicleMakeModel, setVehicleMakeModel } = useQuote();
   const currentYear = new Date().getFullYear();

   const vehicleYears = useMemo(() => {
      const years = [];
      for (let year = currentYear; year >= 1885; year--) {  // Starts at current year, ends at 1985
         years.push({ value: year, label: year });
      }
      return years;
   }, [currentYear]);

   const sortedVehicleMakes = useMemo(() => {
      const options = all_vehicle_make_models?.Results || [];
      return options.sort((a, b) => {
         const nameA = `${a.Make_Name} ${a.Model_Name}`.toUpperCase(); // normalize case
         const nameB = `${b.Make_Name} ${b.Model_Name}`.toUpperCase(); // normalize case
         return nameA.localeCompare(nameB);
      });
   }, [all_vehicle_make_models?.Results]);

   return (<>
          {outputMode === 'table' ?
          <>
          </>
              :
          <>
             <Grid container spacing={1}>
                <Grid item xs={4}>
                   <FormControl fullWidth margin="normal">
                      <InputLabel>Vehicle Year</InputLabel>
                      <Select
                          value={vehicleYear}
                          label="Vehicle Year"
                          onChange={(e) => setVehicleYear(e.target.value)}
                          IconComponent={ExpandMore}
                      >
                         {vehicleYears.map((option) => (
                             <MenuItem key={option.value} value={option.value}>
                                {option.label}
                             </MenuItem>
                         ))}
                      </Select>
                   </FormControl>
                </Grid>
                <Grid item xs={4}>
                   <FormControl fullWidth margin="normal">
                      <Autocomplete
                          value={vehicleMakeModel}
                          onChange={(event, newValue) => {
                             setVehicleMakeModel(newValue);
                             console.log(newValue);
                          }}
                          options={sortedVehicleMakes}
                          getOptionLabel={(option) => {
                              if (!option.Make_Name) return ""; // Return an empty string if option is null or undefined
                              return `${option.Make_Name || ''} ${option.Model_Name || ''}`.trim(); // Ensure no "undefined" strings
                          }}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label="Vehicle Make & Model"
                                  variant="outlined"
                              />
                          )}
                      />
                   </FormControl>
                </Grid>
                <Grid item xs={4}>
                   <FormControl fullWidth margin="normal">
                      <InputLabel>Vehicle Type</InputLabel>
                      <Select
                          value={vehicleType}
                          label="Vehicle Type"
                          onChange={(e) => setVehicleType(toUnderScoreSlug(e.target.value))}
                          IconComponent={ExpandMore}
                      >
                         {sdVehicleOptions.map((option) => (
                             <MenuItem key={option.value} value={option.value}>
                                {option.label}
                             </MenuItem>
                         ))}
                      </Select>
                   </FormControl>
                </Grid>
             </Grid>
          </>}


       </>
   )
};

const VehicleYearSelect = ({ size, value, onChange }) => {
    const { vehicleYear, setVehicleYear } = useQuote();
    const currentYear = new Date().getFullYear();
    const vehicleYears = useMemo(() => {
        const years = [];
        for (let year = currentYear; year >= 1885; year--) {
            years.push({ value: year, label: year });
        }
        return years;
    }, [currentYear]);

    const handleChange = (event) => {
        const selectedYear = event.target.value;
        // If an onChange prop is provided, call it with the new value
        if (onChange) {
            onChange(event);
        } else {
            // Otherwise, update the vehicle year in the context
            setVehicleYear(selectedYear);
        }
    };

    return (
        <FormControl fullWidth margin="normal" size={size}>
            <InputLabel>{size === "small" ? "Year" : "Vehicle Year"}</InputLabel>
            <Select
                size={size}
                value={value !== undefined ? value : vehicleYear}
                label={size === "small" ? "Year" : "Vehicle Year"}
                onChange={handleChange}
                IconComponent={ExpandMore}
            >
                {vehicleYears.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const VehicleMakeModelSelect = ({size}) => {
   const { vehicleMakeModel, setVehicleMakeModel, setVehicleMake, setVehicleModel } = useQuote();

   const sortedVehicleMakes = useMemo(() => {
       const options = all_vehicle_make_models?.Results?.filter(item => !item.Make_Name.includes('Custom')) || [];
      return options.sort((a, b) => {
         const nameA = `${a.Make_Name} ${a.Model_Name}`.toUpperCase();
         const nameB = `${b.Make_Name} ${b.Model_Name}`.toUpperCase();
         return nameA.localeCompare(nameB);
      });
   }, []);

   return (
       <FormControl fullWidth margin="normal" size={size}>
          <Autocomplete size={size}
              value={vehicleMakeModel}
              onChange={(event, newValue) => {
                  setVehicleMakeModel(newValue);
                  setVehicleMake(newValue?.Make_Name);
                  setVehicleModel(newValue?.Model_Name);
              }}
              options={sortedVehicleMakes}
              getOptionLabel={(option) => {
                    return option ? `${option.Make_Name || ''} ${option.Model_Name || ''}`.trim() : "";
                }}
              renderInput={(params) => (
                  <TextField {...params} label="Vehicle Make & Model" variant="outlined" />
              )}
          />
       </FormControl>
   );
};
const VehicleMakeSelect = ({ size, value, onChange }) => {
    const { vehicleMake, setVehicleMake } = useQuote();

    const sortedVehicleMakes = useMemo(() => {
        const makes = all_vehicle_make_models?.Results.map(item => item.Make_Name)
            .filter((v, i, a) => a.indexOf(v) === i) || [];
        return makes.sort((a, b) => a.localeCompare(b));
    }, []);

    const handleChange = (event, newValue) => {
        // Check if onChange is provided and is a function, then call it, otherwise set the vehicle make
        if (onChange) {
            onChange({target:{value:newValue}});
        } else {
            setVehicleMake(newValue);
        }
    };

    return (
        <FormControl fullWidth margin="normal" size={size}>
            <Autocomplete
                size={size}
                value={value !== undefined ? value : vehicleMake}
                onChange={handleChange}
                options={sortedVehicleMakes}
                renderInput={(params) => (
                    <TextField {...params} label={size === 'small' ? 'Make' : "Vehicle Make"} variant="outlined" />
                )}
            />
        </FormControl>
    );
};
const VehicleModelSelect = ({ size, make, value, onChange }) => {
    const { vehicleMake, vehicleModel, setVehicleModel } = useQuote();

    // Determine which make to use - either the prop 'make' or the context 'vehicleMake'
    const effectiveMake = make !== undefined ? make : vehicleMake;

    const sortedVehicleModels = useMemo(() => {
        const models = all_vehicle_make_models?.Results
            .filter(item => item.Make_Name === effectiveMake)
            .map(item => item.Model_Name) || [];
        return models.sort((a, b) => a.localeCompare(b));
    }, [effectiveMake]); // Depend on effectiveMake

    const handleChange = (event, newValue) => {
        // Check if onChange is provided and is a function, call it, otherwise set the vehicle model
        if (onChange) {
            onChange({target:{value:newValue}});
        } else {
            setVehicleModel(newValue);
        }
    };

    return (
        <FormControl fullWidth margin="normal" size={size}>
            <Autocomplete
                size={size}
                value={value !== undefined ? value : vehicleModel}
                onChange={handleChange}
                options={sortedVehicleModels}
                noOptionsText="Vehicle Make Required"
                renderInput={(params) => (
                    <TextField {...params} label={size === 'small' ? 'Model' : "Vehicle Model"} variant="outlined" />
                )}
            />
        </FormControl>
    );
};

const VehicleTypeSelect = ({ size, value, onChange }) => {
    const { vehicleType, setVehicleType } = useQuote();
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        } else {
            setVehicleType(event.target.value);
        }
    };

    return (
        <FormControl fullWidth margin="normal" size={size}>
            <InputLabel>{size === "small" ? "Type" : "Vehicle Type"}</InputLabel>
            <Select
                size={size}
                value={value !== undefined ? value : vehicleType}
                label={size === "small" ? "Type" : "Vehicle Type"}
                onChange={handleChange}
                IconComponent={ExpandMore}
            >
                {sdVehicleOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const VehicleSelection = ({ outputMode = 'grid', size }) => {
    return (
        <>
            {outputMode === 'table' ? (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <tbody>
                    <tr>
                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                            <VehicleYearSelect size={size} />
                        </td>
                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                            <VehicleMakeModelSelect size={size} />
                        </td>
                        <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                            <VehicleTypeSelect size={size} />
                        </td>
                    </tr>
                    </tbody>
                </table>
            ) : (
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <VehicleYearSelect />
                    </Grid>
                    <Grid item xs={4}>
                        <VehicleMakeModelSelect />
                    </Grid>
                    <Grid item xs={4}>
                        <VehicleTypeSelect />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default VehicleSelection;


export { VehicleMakeSelect, VehicleModelSelect, VehicleYearSelect, VehicleMakeModelSelect, VehicleTypeSelect, VehicleSelection };
