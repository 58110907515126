import React, {useMemo, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import RouteInfo from "../Dashboard/RouteInfo";
import {postQuoteConfirm} from "../../lib/api";
import useCurrentUser from "../../lib/hooks/useCurrentUser";
import {LoadingButton} from "@mui/lab";
import {useGlobal} from "../../lib/contexts";
import useOrders from "../../lib/hooks/useOrders";
import {useQuote} from "../../lib/contexts/QuoteContext";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import EmailInput from "../EmailInput/EmailInput";
import DateTimeInput from "../DateTimeInput/DateTimeInput";
import VINManagementTable from "../VINManagementTable/VINManagementTable";
import {formatMoney} from "../../lib/helpers/helpers";

export const OrderClaimDialog = ({
                                        isOpen,
                                        onClose,
                                        quote,
                                        description = "Please confirm the most up-to-date Contact Information for both the Pick-up Location and Drop-off Location."
                                    }) => {
    const [isSaving, setIsSaving] = useState(false);
    const { token, userID } = useCurrentUser();
    const { refetch } = useOrders()
    const { resetContext,
        pickupContactPhoneNumber, setPickupContactPhoneNumber,
        pickupContactEmail, setPickupContactEmail,
        dropoffContactPhoneNumber, setDropoffContactPhoneNumber,
        pickupDateTime, setPickupDateTime,
        dropoffDateTime, setDropoffDateTime,
        dropoffContactEmail, setDropoffContactEmail,
        vehicles
    } = useQuote();
    const { setSnackbar } = useGlobal();
    const depositDue = useMemo(() => {
        return formatMoney(((quote?.price || 0) * (quote?.deposit_percentage || 0)) * (vehicles.length || 1));
    }, [quote, vehicles]);
    //console.log('vehicles.length',vehicles.length)
    //console.log('deposit_percentage',quote?.deposit_percentage)
    //console.log('deposit_percentage',quote?.price)
    const handleClaimQuote = async () => {
        setIsSaving(true)

        const claimObject = {
            id: quote.id,
            pickup_contact_email: pickupContactEmail,
            pickup_contact_phone_number: pickupContactPhoneNumber,
            pickup_datetime: pickupDateTime,
            dropoff_contact_email: dropoffContactEmail,
            dropoff_contact_phone_number: dropoffContactPhoneNumber,
            dropoff_datetime: dropoffDateTime,
            vehicles: vehicles
        } || {}


        try {
            // Call the postQuoteConfirm function and wait for its result
            const { data, error } = await postQuoteConfirm(token, userID, claimObject);

            // Check if there's an error in the response
            if (error) {
                throw new Error(error);
            }

            // Log and handle the success case
           // console.log(`Quote ${quote.id} claimed!`, data);
            setSnackbar({
                open: true,
                message: "Success! The quote has been claimed.",
                severity: 'success',
            });

            // Additional actions like refetching data
            refetch();
            resetContext()
            onClose();
        } catch (error) {
            // Error handling if there's an error from postQuoteConfirm or the throw above
            console.error("Failed to claim quote:", error.message);
            setSnackbar({
                open: true,
                message: `Oops! Something went wrong. Couldn't claim the quote: ${error.message}`,
                severity: 'error',
            });
        } finally {
            setIsSaving(false)
        }
    };

    const isValid = pickupContactPhoneNumber &&
        pickupContactEmail &&
        dropoffContactPhoneNumber &&
        dropoffContactEmail &&
        pickupDateTime &&
        dropoffDateTime &&
        vehicles.length > 0;


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'lg'}
            open={isOpen}
            onClose={onClose}
            aria-labelledby="quote-claim-dialog-title"
            aria-describedby="quote-claim-dialog-description"
        >
            <DialogTitle id="driver-checkin-dialog-title">Claim Quote</DialogTitle>
            <DialogContent>
                <RouteInfo showTitle={false}
                           quoteMode={true}
                           overRideQuoteOrder={quote}
                           quantityMultiple={vehicles.length}
                />
                <br/>
<Grid container spacing={2}>
    <Grid item xs={12}>
        <Card>
            <CardContent>
                <VINManagementTable
                    quoteID={quote.id}
                    id="vin_management"
                    label="Vehicles"
                    disabled={isSaving}
                />
            </CardContent>
        </Card>
    </Grid>
</Grid>
                <br/>
                <DialogContentText id="quote-claim-dialog-description">
                    {description}
                </DialogContentText>
                <br/>
<Grid container spacing={2}>

    <Grid item xs={6}>
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Pick-up Contact Information</Typography>
                <PhoneNumberInput
                    label={`Contact Phone Number`}
                    id="pickup_contact_phone"
                    value={pickupContactPhoneNumber}
                    onChange={(e) => setPickupContactPhoneNumber(e.target.value)}
                    disabled={isSaving}
                />
                <EmailInput
                    id="pickup_contact_email"
                    label={`Contact Email`}
                    value={pickupContactEmail}
                    onChange={(e) => setPickupContactEmail(e.target.value)}
                    disabled={isSaving}
                />
                <DateTimeInput
                    id="pickup_datetime"
                    label="Desired Pick-up Date & Time"
                    value={pickupDateTime}
                    onChange={(e) => setPickupDateTime(e.target.value)}
                    disabled={isSaving}
                    error={dropoffDateTime <= pickupDateTime}
                    minDateTime={new Date().toISOString().slice(0, 16)}
                />
            </CardContent>
        </Card>
    </Grid>
    <Grid item xs={6}>
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>Drop-off Contact Information</Typography>
                <PhoneNumberInput
                    id="dropoff_contact_phone"
                    label={`Contact Phone Number`}
                    value={dropoffContactPhoneNumber}
                    onChange={(e) => setDropoffContactPhoneNumber(e.target.value)}
                    disabled={isSaving}
                />
                <EmailInput
                    id="dropoff_contact_email"
                    label={`Contact Email`}
                    value={dropoffContactEmail}
                    onChange={(e) => setDropoffContactEmail(e.target.value)}
                    disabled={isSaving}
                />
                <DateTimeInput
                    id="dropoff_datetime"
                    label="Desired Drop-off Date & Time"
                    value={dropoffDateTime}
                    onChange={(e) => setDropoffDateTime(e.target.value)}
                    disabled={isSaving}
                    error={dropoffDateTime <= pickupDateTime}
                    minDateTime={pickupDateTime || new Date().toISOString().slice(0, 16)}
                />
            </CardContent>
        </Card>
    </Grid>
</Grid>


            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary"
                        disabled={isSaving}>
                    Cancel
                </Button>
                <LoadingButton onClick={handleClaimQuote} color="primary" autoFocus
                        disabled={!isValid || isSaving}
                loading={isSaving}>
                    {quote?.deposit_percentage ? `Confirm ${depositDue} Deposit` : `Confirm`}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
