// QuoteContext.js
import React, {createContext, useContext, useEffect, useState} from 'react';

const QuoteContext = createContext();

export const useQuote = () => useContext(QuoteContext);

export const QuoteProvider = ({ children, defaultMode = 'Auto' }) => {
    const isDev = false //window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1');

   const [tabValue, setTabValue] = useState(0); // Assuming 'Auto' as default
   const [quotes, setQuotes] = useState([]);
   const [didSearch, setDidSearch] = useState(false);
   const [isPosting, setIsPosting] = useState(false);
   const [pickupLocation, setPickupLocation] = useState();
   const [dropoffLocation, setDropoffLocation] = useState();
   const [vehicleVIN, setVehicleVIN] = useState('');
   const [vehicleType, setVehicleType] = useState('');
   const [ vehicleYear, setVehicleYear ] = useState(null);
   const [ vehicleMake, setVehicleMake ] = useState('');
   const [ vehicleModel, setVehicleModel ] = useState('');
   const [ vehicleMakeModel, setVehicleMakeModel ] = useState('');
   const [vesselType, setVesselType] = useState('');
   const [deliverySpeed, setDeliverySpeed] = useState('standard');
   const [insurance, setInsurance] = useState('standard');
   const [transportType, setTransportType] = useState('open');
   const [vehicleCondition, setVehicleCondition] = useState('operational');

   const [dimensions, setDimensions] = useState({ length: null, width: null, height: null, beamWidth: null });
   const [desiredPickupDate, setDesiredPickupDate] = useState('');
   const [desiredDeliveryDate, setDesiredDeliveryDate] = useState('');
   const [recurring, setRecurring] = useState({
      isRecurring: false,
      frequency: '',
      start_date: '',
      end_date: '',
   });
   const [materialType, setMaterialType] = useState('');
   const [materialGrade, setMaterialGrade] = useState('');
   const [showInsuranceValue, setShowInsuranceValue] = useState(false);
   const [insuranceRequired, setInsuranceRequired] = useState(false);
   const [insuranceValue, setInsuranceValue] = useState(false);
   const [hazardousMaterials, setHazardousMaterials] = useState(false);
   const [specialHandling, setSpecialHandling] = useState([]);
   const [pickupContactPhoneNumber, setPickupContactPhoneNumber] = useState(null);
   const [pickupContactEmail, setPickupContactEmail] = useState('');
   const [dropoffContactPhoneNumber, setDropoffContactPhoneNumber] = useState(null);
   const [dropoffContactEmail, setDropoffContactEmail] = useState('');
   const [pickupDateTime, setPickupDateTime] = useState(new Date());
   const [dropoffDateTime, setDropoffDateTime] = useState(new Date());

   const [vehicles, setVehicles] = useState([]);

   useEffect(() => {
      if(isDev &&
          !pickupLocation && !dropoffLocation && !vehicleVIN && !vehicleType && !vehicleYear && !vehicleMakeModel){

         setVehicleType('Sedan')
         setVehicleYear(2020)
         setVehicleVIN('12345678912345678')
         setPickupLocation(dropoffLocation)
         setDropoffLocation(pickupLocation)

      }
   }, [pickupLocation, dropoffLocation, isDev, vehicleVIN, vehicleType, vehicleYear, vehicleMakeModel]);

   const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'info',
   });
   const resetContext = () => {
      setTabValue(0);
      setQuotes([]);
      setDidSearch(false);
      setIsPosting(false);
      setPickupLocation('');
      setDropoffLocation('');
      setVehicleYear(null)
      setVehicleVIN('');
      setVehicleType('');
      setVesselType('');
      setDeliverySpeed('standard');
      setInsurance('standard');
      setTransportType('open');
      setVehicleCondition('operational');
      setDimensions({ length: null, width: null, height: null, beamWidth: null });
      setDesiredPickupDate('');
      setDesiredDeliveryDate('');
      setRecurring({
         isRecurring: false,
         frequency: '',
         start_date: '',
         end_date: '',
      });
      setMaterialType('');
      setMaterialGrade('');
      setShowInsuranceValue(false);
      setInsuranceRequired(false);
      setInsuranceValue(false);
      setHazardousMaterials(false);
      setSpecialHandling([]);
      setSnackbar({
         open: false,
         message: '',
         severity: 'info',
      });
   };
   useEffect(() => {
      // Update tabValue when defaultMode changes
      setTabValue(defaultMode === 'Auto' ? 0 : 1);
   }, [defaultMode]);

   const value = {
      tabValue,
      setTabValue,
      isPosting,
      setIsPosting,
      pickupLocation,
      setPickupLocation,
      dropoffLocation,
      setDropoffLocation,
      vehicleType,
      setVehicleType,
      deliverySpeed,
      setDeliverySpeed,
      insurance,
      setInsurance,
      transportType,
      setTransportType,
      vehicleCondition,
      setVehicleCondition,
      vehicleVIN, setVehicleVIN,
      vesselType, setVesselType,
      quotes, setQuotes,
      snackbar, setSnackbar,
      dimensions, setDimensions,
      desiredPickupDate, setDesiredPickupDate,
      desiredDeliveryDate, setDesiredDeliveryDate,
      recurring, setRecurring,
      materialType, setMaterialType,
      materialGrade, setMaterialGrade,
      showInsuranceValue, setShowInsuranceValue,
      insuranceRequired, setInsuranceRequired,
      insuranceValue, setInsuranceValue,
      specialHandling, setSpecialHandling,
      hazardousMaterials, setHazardousMaterials,
      vehicleYear, setVehicleYear,
      vehicleMake, setVehicleMake,
      vehicleModel, setVehicleModel,
      vehicleMakeModel, setVehicleMakeModel,
      didSearch, setDidSearch,
      pickupContactPhoneNumber, setPickupContactPhoneNumber,
      pickupContactEmail, setPickupContactEmail,
      dropoffContactPhoneNumber, setDropoffContactPhoneNumber,
      dropoffContactEmail, setDropoffContactEmail,
      pickupDateTime, setPickupDateTime,
      dropoffDateTime, setDropoffDateTime,
      vehicles, setVehicles,
      resetContext
   };

   return (
      <QuoteContext.Provider value={value}>
         {children}
      </QuoteContext.Provider>
   );
};
