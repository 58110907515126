import React from 'react';
import {
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography,
} from "@mui/material";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { green, orange, red } from '@mui/material/colors';
import RefreshButton from "../RefreshButton/RefreshButton";

const shipmentsData = [
    { id: 1, status: 'In Transit', estDelivery: '15-Apr-2024', icon: <LocalShippingIcon sx={{ color: green[500] }}/> },
    { id: 2, status: 'Delayed', estDelivery: '17-Apr-2024', icon: <LocalShippingIcon sx={{ color: orange[500] }}/> },
    { id: 3, status: 'Delivered', estDelivery: '12-Apr-2024', icon: <LocalShippingIcon sx={{ color: green[500] }}/> },
    { id: 4, status: 'Cancelled', estDelivery: 'N/A', icon: <LocalShippingIcon sx={{ color: red[500] }}/> },
];

const CurrentShipmentsProgress = () => {
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Current Shipments in Progress
                    <RefreshButton onRefresh={()=>{}} isLoading={true} />

                </Typography>
                <List>
                    {shipmentsData.map((shipment) => (
                        <ListItem key={shipment.id}>
                            <ListItemIcon>
                                {shipment.icon}
                            </ListItemIcon>
                            <ListItemText
                                primary={`Order #${shipment.id} - ${shipment.status}`}
                                secondary={`Estimated Delivery: ${shipment.estimated_delivery_time}`}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default CurrentShipmentsProgress;
