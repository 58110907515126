// TrackForm.js

import React from 'react';
import { Box, Grid, Snackbar, TextField, Typography } from '@mui/material';
import {Alert, LoadingButton} from "@mui/lab";
import { useTrack, TrackProvider } from "../../lib/contexts/TrackContext";
import OrderList from "../OrderList/OrderList";
import {useGlobal} from "../../lib/contexts";

const TrackForm = () => {
   const {
      trackId, setTrackId,
      isTracking, setIsTracking,
      setSnackbar
   } = useTrack();

   const handleSubmit = (event) => {
      event.preventDefault(); // Prevent default form submission behavior
      console.log(`Tracking request for: ${trackId}`);

      setIsTracking(true);
      setSnackbar({
         open: true,
         message: "Searching for your tracking details...",
         severity: 'info',
      });

      // Simulate an API call
      setTimeout(()=>{
         setIsTracking(false);
         // On success:
         // setSnackbar({
         //   open: true,
         //   message: 'Details found. Your Transport is on the way!',
         //   severity: 'success',
         // });
         // On failure:
         // setSnackbar({
         //   open: true,
         //   message: 'Unable to find details for the provided tracking ID.',
         //   severity: 'error',
         // });
      }, 5000);
   };

   const handleChange = (event) => {
      setTrackId(event.target.value);
   };

   return (
      <Box sx={{ p: 2 }}>
         <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6}>
               <Typography variant="h6" textAlign="center" gutterBottom>
                  Track Your Order
               </Typography>
               <form onSubmit={handleSubmit}>
                  <TextField
                     fullWidth
                     label="Phone Number or Tracking ID"
                     variant="outlined"
                     value={trackId}
                     onChange={handleChange}
                     sx={{ mb: 2 }}
                  />
                  <Box textAlign="center">
                     <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isTracking}
                     >
                        Track
                     </LoadingButton>
                  </Box>
               </form>
            </Grid>
            <Grid item xs={12} md={6}>
               <OrderList />
            </Grid>
         </Grid>
      </Box>
   );
};

function TrackSnackbarWrapper() {
   const {
      snackbar, setSnackbar
   } = useGlobal();
   const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setSnackbar({ ...snackbar, open: false });
   };
   return (
      <>
         <TrackForm />
      </>
   );
}

function TrackWrapper() {
   return (
      <TrackProvider>
         <TrackSnackbarWrapper/>
      </TrackProvider>
   );
}

export default TrackWrapper;
