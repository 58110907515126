import axios from 'axios';
import useCurrentUser from '../hooks/useCurrentUser';
import { API_HOST } from '../constants/constants';
export const getMetric = async (metricName, token, userID) => {
  const apiURL =  API_HOST + '/metrics/' + metricName;
  if(!token){
    return false
  }
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
