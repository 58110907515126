import React, {useEffect, useMemo, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {prettyGPS} from "../../lib/helpers/helpers";
import RouteInfo from "../Dashboard/RouteInfo";
import {postDriverCheckin} from "../../lib/api";
import useCurrentUser from "../../lib/hooks/useCurrentUser";
import {LoadingButton} from "@mui/lab";
import {useGlobal} from "../../lib/contexts";
import {getFetchAddress} from "../../lib/api";
import useSummary from "../../lib/hooks/useSummary";
import UploadMediaDialog from "../UploadMediaDialog/UploadMediaDialog";

export const DriverCheckinDialog = ({
                                        isOpen,
                                        onClose,
                                        description = "Please update the status of your shipment, including any relevant notes or issues. Customers love Photo-Checkins!"
                                    }) => {
    const [gps, setGps] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('');
    const [address, setAddress] = useState('');
    const [fetchedPlaces, setFetchedPlaces] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const { token, userID } = useCurrentUser();
    const { setSnackbar } = useGlobal();
    const { data, isLoading, isRefetching, refetch } = useSummary()
    const orderID = useMemo(() => data?.route_info?.id, [data])
    const driverID = useMemo(() => data?.driver_info?.id, [data])

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setGps(`${latitude}, ${longitude}`);
                    setLatitude(latitude);
                    setLongitude(longitude);
                  //  fetchAddress(latitude, longitude);
                },
                (error) => {
                    console.error('Error getting location: ', error);
                },
                {
                    enableHighAccuracy: true
                }
            );
        } else {
            console.log('Geolocation is not supported by this browser.');
        }
    }, []);

    useEffect(() => {
        const getPlaces = async () => {
            const fetchedPlaces = await getFetchAddress(latitude, longitude, true, token, userID);
            if (fetchedPlaces) {
                setSelectedPlace(fetchedPlaces.address[0]);
                setFetchedPlaces(fetchedPlaces.address);
            }
        };
        if(latitude && longitude){
            getPlaces();
        }
    }, [latitude, longitude]);
/*
    const fetchAddress = (lat, lng) => {
        const url = API_HOST`/locations/fetch_address?lat=${lat}&lng=${lng}`;

        axios.get(url)
            .then(response => {
                if (response.data.address) {
                    setAddress(response.data.address);
                } else {
                    console.error('No address found for the given coordinates.');
                }
            })
            .catch(error => console.error('Error fetching address:', error));
    };
*/

    const handlePhotoChange = (event) => {
        setPhoto(event.target.files[0]);
    };

    const onConfirm = async () => {
        const checkinObject = {
            notes: notes,
            //photo: photo, needs to be a presigned url separate s3 request setup
            order_id: orderID,  // Ensure this is a valid ID that exists in your database
            status: status,
            location_attributes: {
                google_place_id: selectedPlace.place_id,  // Assuming selectedPlace has a place_id directly from Google API
                coordinates: `POINT(${selectedPlace.geometry.location.lng} ${selectedPlace.geometry.location.lat})`,  // Format for PostGIS, adjust according to your backend setup
                address: selectedPlace.formatted_address,  // Simplified direct attribute for address
                // Additional fields as required by your backend, possibly including:
                city: selectedPlace.address_components.find(component => component.types.includes("locality")).long_name,
                state: selectedPlace.address_components.find(component => component.types.includes("administrative_area_level_1")).short_name,
                postal_code: selectedPlace.address_components.find(component => component.types.includes("postal_code")).long_name,
                country: selectedPlace.address_components.find(component => component.types.includes("country")).short_name
            }
        };

       // console.log(checkinObject)
        setIsSaving(true);

        try {
            const result = await postDriverCheckin(token, userID, checkinObject);

            if (result.error) {
                throw new Error(result.error.message || JSON.stringify(result.error) || 'An unknown error occurred');
            }

            setSnackbar({
                open: true,
                message: "Check-in Saved.",
                severity: 'success',
            });
            onClose();
        } catch (error) {
            console.error("Failed to claim check-in:", error.message);
            setSnackbar({
                open: true,
                message: `Something went wrong. Couldn't check-in: ${error.message}`,
                severity: 'error',
            });
        } finally {
            setIsSaving(false);
        }
    };


    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={isOpen}
            onClose={onClose}
            aria-labelledby="driver-checkin-dialog-title"
            aria-describedby="driver-checkin-dialog-description"
        >
            <DialogTitle id="driver-checkin-dialog-title">Driver Check-In</DialogTitle>
            <DialogContent>
                <RouteInfo showTitle={false}/>
                <br/>
                <DialogContentText id="driver-checkin-dialog-description">
                    {description}
                </DialogContentText>
                <br/>

                <FormControl fullWidth margin="dense">
                    <InputLabel id="address-label">{`Current Location (GPS: ${prettyGPS(gps)})`}</InputLabel>
                <Select
                    fullWidth={true}
                    disabled={isSaving}
                    labelId="address-label"
                    id="address"
                    label={`Current Location (GPS: ${prettyGPS(gps)})`}
                    value={selectedPlace}
                    onChange={(e) => setSelectedPlace(e.target.value)}
                >
                    {fetchedPlaces && fetchedPlaces.slice(0, 4).map((place) => (
                        <MenuItem key={place.place_id} value={place}>
                            {place.formatted_address}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
                <FormControl fullWidth margin="dense">
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                       disabled={isSaving}
                        labelId="status-label"
                        id="status"
                        value={status}
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <MenuItem value="en_route">En Route</MenuItem>
                        <MenuItem value="delivered">Delivered</MenuItem>
                        <MenuItem value="delayed">Delayed</MenuItem>
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                        <MenuItem value="loading">Loading</MenuItem>
                        <MenuItem value="unloading">Unloading</MenuItem>
                        <MenuItem value="loaded">Loaded</MenuItem>
                        <MenuItem value="at_origin">At Origin</MenuItem>
                        <MenuItem value="at_destination">At Destination</MenuItem>
                        <MenuItem value="awaiting_instructions">Awaiting Instructions</MenuItem>
                        <MenuItem value="vehicle_issue">Vehicle Issue</MenuItem>
                        <MenuItem value="traffic_delay">Traffic Delay</MenuItem>
                        <MenuItem value="customs_hold">Customs Hold</MenuItem>
                        <MenuItem value="weather_delay">Weather Delay</MenuItem>
                        <MenuItem value="refueling">Refueling</MenuItem>
                        <MenuItem value="maintenance_required">Maintenance Required</MenuItem>
                        <MenuItem value="emergency">Emergency</MenuItem>
                        <MenuItem value="documentation_issue">Documentation Issue</MenuItem>
                        <MenuItem value="inspection_required">Inspection Required</MenuItem>
                        <MenuItem value="security_check">Security Check</MenuItem>
                        <MenuItem value="rest_break">Rest Break</MenuItem>
                        <MenuItem value="border_crossing">Border Crossing</MenuItem>
                        <MenuItem value="schedule_change">Schedule Change</MenuItem>
                        <MenuItem value="route_deviation">Route Deviation</MenuItem>
                        <MenuItem value="no_show">No Show</MenuItem>
                        <MenuItem value="waiting_for_clearance">Waiting for Clearance</MenuItem>
                    </Select>
                </FormControl>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="icon-button-file"
                    type="file"
                    onChange={handlePhotoChange}
                />
                <label htmlFor="icon-button-file">
                    <UploadMediaDialog
                        orderID={orderID}
                        entityID={driverID} // todo - this should probably be checkin id
                        title={'Add a Photo or Video to your Check-in'}
                        uploadType={'driver_checkin'}
                        disabled={isSaving}
                        onChange={()=>{}}
                        onSuccess={()=>{}}
                        onClose={()=>{}}
                    />
                </label>
                <TextField
                   disabled={isSaving}
                    margin="dense"
                    id="notes"
                    label="Notes"
                    type="text"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary"
                        disabled={isSaving}>
                    Cancel
                </Button>
                <LoadingButton onClick={onConfirm} color="primary" autoFocus
                        disabled={!selectedPlace || !status || isSaving}
                loading={isSaving}>
                    Confirm
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
