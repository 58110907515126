import { useQuery } from 'react-query';
import { getMetric } from '../api/getMetric';
import useCurrentUser from "./useCurrentUser";

const useMetric = (metricName= 0, token, userID) => {
  //const { token, userID } = useCurrentUser();
  if(!token){
    return false
  }
  const result = useQuery('metric_'+ metricName, () => getMetric(metricName, token, userID), {
    refetchOnWindowFocus: false,
    staleTime: 5000///50000,
  });
  return result;
};

export default useMetric;
