// DriverSummary.js
import React, {useMemo, useState} from 'react';
import {
    Card,
    CardContent,
    Typography,
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Button,
    Box,
    Rating,
} from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import MessageIcon from '@mui/icons-material/Message';
import IconButton from '@mui/icons-material/PhotoCamera';
import useSummary from "../../lib/hooks/useSummary";


const DriverSummary = ({ mode = 'track' }) => {
    const { data, isLoading, isRefetching, refetch } = useSummary()
    const driverInfo = useMemo(() => data?.driver_info, [data])

    const [selectedFile, setSelectedFile] = useState(null);
    const handleAvatarClick = () => {
        if (mode === 'driver') {
            document.getElementById('file-upload').click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(URL.createObjectURL(file));
        }
    };
    const getDriverName = (name) => {
        const [firstName, lastName] = name.split(' ');
        return `${firstName} ${lastName.charAt(0).toUpperCase()}.`;
    };

    // Function to handle the call action
    const handleCall = () => {
        console.log("Calling driver:", driverInfo.phone_number);
        // Insert the code to initiate a phone call here
        // This might involve something like redirecting to a tel: URI
        window.location.href = `tel:${driverInfo.phone_number}`;
    };

    // Function to handle the text action
    const handleText = () => {
        console.log("Texting driver:", driverInfo.phone_number);
        // Insert the code to initiate sending a text message here
        // This might involve something like redirecting to an sms: URI
        window.location.href = `sms:${driverInfo.phone_number}`;
    };
    return (
        <Card style={{minHeight: mode === 'driver' ? 380 : 320}}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {mode === 'driver' ? 'Your Details' : 'Driver Details'}
                </Typography>
                <List>
                    <ListItem>
                        <ListItemAvatar>
                            <input
                                accept="image/*"
                                id="file-upload"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <Avatar
                                alt={mode === 'driver' ? driverInfo?.name : driverInfo?.name ? getDriverName(driverInfo?.name) : 'Loading' }
                                src={selectedFile || driverInfo?.avatar}
                                onClick={handleAvatarClick}
                                sx={{ cursor: mode === 'driver' ? 'pointer' : 'default' }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={mode === 'driver' ? driverInfo?.name : driverInfo?.name ? getDriverName(driverInfo?.name) : 'Loading' }
                            secondary={
                                <>
                                    <Rating value={driverInfo && driverInfo.rating ? driverInfo.rating : 0} readOnly /> ({driverInfo?.reviews} reviews)
                                </>
                            }
                        />
                    </ListItem>
                    {mode === 'track' && (
                        <>
                            <ListItem>
                                <Button startIcon={<PhoneIcon />} variant="outlined"
                                        onClick={handleCall}>
                                    Call
                                </Button>
                                <Button startIcon={<MessageIcon />} sx={{ ml: 1 }} variant="outlined"
                                        onClick={handleText}>
                                    Text
                                </Button>
                            </ListItem>
                        </>
                    )}
                    {mode === 'driver' && (
                        <>
                            <ListItem>
                                <ListItemText
                                    primary="Public Information"
                                    secondary="Your first name, photo, and rating are visible to customers."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Tips to Improve Rating" secondary={
                                    <ul>
                                        {driverInfo?.improvementTips.map((tip, index) => (
                                            <li key={index}>{tip}</li>
                                        ))}
                                    </ul>
                                }/>
                            </ListItem>
                        </>
                    )}
                </List>
            </CardContent>
        </Card>
    );
};

export default DriverSummary;

