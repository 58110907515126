import React, {useContext, useMemo, useState} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../lib/assets/images/logo.png'
import OffCanvasMenu from './OffCanvasMenu';
import {useMode, UserContext} from "../lib/contexts";

import {
    IconButton,
    Box, Container,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import {Menu} from '@mui/icons-material';

const Header = (props) => {
    const theme = useTheme();
    const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));
    const {
        state: {
            user_id
        },
        actions: {
            logout,
        },
    } = useContext(UserContext);
    const production = useMemo(()=>window.location.hostname === 'carrierconnector.com',[]);
    const { mode } = useMode();
    // Define nav items based on the mode
    let navItems = [];
    const [menuOpen, setMenuOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMenuOpen(!menuOpen);
    };

    switch (mode) {
        case 'track':
            navItems = [
                { name: 'Track Shipment', path: '/track' },
                //{ name: 'Shipment History', path: '/history' },
                { name: 'FAQ', path: '/faq' },
                { name: user_id ? "Logout" : "Login", onClick: user_id ? () => logout() : () => { } },
            ];
            break;
        case 'driver':
            navItems = [
                { name: 'Current Route', path: '/current' },
                { name: 'Past Routes', path: '/history' },
                //{ name: 'Expenses', path: '/expenses' },
                { name: 'Profile', path: '/profile' },
                { name: user_id ? "Logout" : "Login", onClick: user_id ? () => logout() : () => { } },
            ];
            break;
        case 'dashboard':
            navItems = [
                { name: 'Dashboard', path: '/dashboard', mobileMenuOnly: true },
                { name: 'Quote', path: '/quote' },
                { name: 'Shipments', path: '/shipments' },
                { name: 'Map', path: '/map' },
                //{ name: 'Reports', path: '/reports' },
                //{ name: 'Settings', path: '/settings' },
                { name: user_id ? "Logout" : "Login", onClick: user_id ? () => logout() : () => { } },
            ];
            break;
        default:
        case 'admin':
            navItems = [
                { name: 'Dashboard', path: '/dashboard', mobileMenuOnly: true },
                { name: 'Quote', path: '/quote' },
                { name: 'Shipments', path: '/history' },
                { name: 'Map', path: '/map' },
               // { name: 'Reports', path: '/reports' },
                { name: user_id ? "Logout" : "Login", onClick: user_id ? () => logout() : () => { } },
            ];
            break;
    }

    const disableNavigation = !(mode === 'admin' || mode === 'dashboard');

    return (
        <>

            <Box component="header" sx={{ width: '100%', position: 'sticky', top: 0, zIndex: 1100, backgroundColor: '#001542', color: '#fff', height: 92 }}>
                <Container maxWidth={user_id ? 'lg' : 'md'}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={12}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '10px',
                                justifyContent: user_id && !disableNavigation ? 'space-between' : 'center',
                                width: '100%'
                            }}>
                                <Link to="/">
                                    <img src={logo} alt="logo" style={{ marginTop: 15, height: 'auto', maxWidth: matchSM ? '90%' : 300 }} />
                                </Link>
                                {!disableNavigation && matchSM  && user_id && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        edge="start"
                                        onClick={handleDrawerToggle}
                                        sx={{ ml: 1, mt: 2 }}
                                    >
                                        <Menu />
                                    </IconButton>
                                )}
                                {!disableNavigation && user_id && (
                                    <>
                                        <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'flex-end', width: '100%' }}>
                                            <nav>
                                                <List sx={{ display: 'flex', flexDirection: 'row', marginBottom: '-15px', padding: 0 }}>
                                                    { navItems.filter(item => !item.mobileMenuOnly).map((item, index) => (
                                                        <ListItem key={index} disablePadding sx={{ marginRight: theme.spacing(0) }} style={{ minWidth:150, textAlign: 'center', alignContent:'center', alignSelf:'center', justifyContent:'center', alignItems:'center', justifyItems:'center' }}>
                                                            <ListItemButton component="a"  href={item.path ? item.path : undefined}
                                                                            onClick={item.onClick ? item.onClick : undefined}
                                                                            style={{ textAlign: 'center', alignContent:'center', alignSelf:'center', justifyContent:'center', alignItems:'center', justifyItems:'center', flex: 'none' }}>
                                                                <ListItemText primary={item.name}  />
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </nav>
                                        </Box>
                                    </>
                                )}
                            </Box>

                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {!disableNavigation && user_id &&
                <OffCanvasMenu navItems={navItems} open={menuOpen} setMenuOpen={setMenuOpen}></OffCanvasMenu>}
        </>
    );
}

export default Header;
