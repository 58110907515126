import axios from 'axios';
import { API_HOST } from '../constants/constants';

/**
 * Fetches the formatted address for a given latitude and longitude.
 * @param {number} lat - The latitude of the location.
 * @param {number} lng - The longitude of the location.
 * @param full_list
 * @param {string} token - User authentication token.
 * @param {string} userID - User ID.
 * @returns {Promise<string | undefined>} A promise that resolves to the formatted address or undefined if an error occurs.
 */
export const getFetchAddress = async (lat, lng, full_list = true, token, userID) => {
    if (!lat || !lng) {
        console.error('Latitude and longitude are required for fetching address.');
        return undefined;
    }

    const apiURL = `${API_HOST}/locations/fetch_address?lat=${lat}&lng=${lng}`;
    try {
        const response = await axios.get(apiURL, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-gg-user-id': userID
            }
        });
        if (response.data) {
            return full_list ? response.data : response.data[0]["formatted_address"];
        } else {
            console.error('No address found for the given coordinates.');
            return undefined;
        }
    } catch (error) {
        console.error('Error fetching address:', error);
        return undefined;
    }
};
