// WeightAndQuantityFields.js
import React from 'react';
import {FormControl, TextField, Box, Grid} from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

const WeightAndQuantityFields = () => {
   const { unitWeight, setUnitWeight, totalWeight, setTotalWeight, quantity, setQuantity } = useQuote();
   
   return (
      <Box>
      <Grid container spacing={2}>
         <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
               <TextField
                  label="Unit Weight (lbs)"
                  value={unitWeight}
                  onChange={(e) => setUnitWeight(e.target.value)}
                  type="number"
               />
            </FormControl>
         </Grid>
         <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
               <TextField
                  label="Total Weight (lbs)"
                  value={totalWeight}
                  onChange={(e) => setTotalWeight(e.target.value)}
                  type="number"
               />
            </FormControl>
         </Grid>
         <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
               <TextField
                  label="Unit Quantity"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  type="number"
               />
            </FormControl>
         </Grid>
         {/* If you need to add a fourth field or just want to maintain the grid layout symmetry,
          you might add an empty Grid item here or adjust according to your UI needs */}
      </Grid>
      </Box>
   );
};

export default WeightAndQuantityFields;
