import axios from 'axios';
import { API_HOST } from '../constants/constants';

export const getUploadPresign = async (fileExtension, filename, fileSize, contentType, token = '', quoteID = '', orderID = '', entityID = '', mediaDetails) => {
  // Validate required parameters
  if (!fileExtension || !filename || !fileSize || !contentType || !token) {
    console.error("Missing required parameters.");
    return false;
  }

  // URL encode parameters to ensure special characters are handled correctly
  const encodedFilename = encodeURIComponent(filename);
  const encodedFileExtension = encodeURIComponent(fileExtension);
  const encodedFileSize = encodeURIComponent(fileSize);

  // Construct the API URL with encoded parameters
  const encodedMediaDetails = encodeURIComponent(JSON.stringify(mediaDetails));
  const apiURL = `${API_HOST}/upload?filename=${encodedFilename}&file_extension=${encodedFileExtension}&file_size=${encodedFileSize}&content_type=${contentType}&entity_id=${entityID}&quote_id=${quoteID}&order_id=${orderID}&media=${encodedMediaDetails}`;

  try {
    const response = await axios.get(apiURL, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error requesting presigned URL:', error);
    return null;
  }
};
