// DimensionsFields.js
import React from 'react';
import { FormControl, Grid, TextField, Box } from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

const DimensionsFields = ({mode}) => {
   const { dimensions, setDimensions, totalWeight, setTotalWeight } = useQuote(); // Assume dimensions is an object { length, width, height, beamWidth }
   
   const handleChange = (name) => (event) => {
      setDimensions({...dimensions, [name]: event.target.value});
   };
   
   return (
      <Box>
         <Grid container spacing={2}>
            <Grid item xs={6}>
               <FormControl fullWidth margin="normal">
                  <TextField
                     label={`${mode === "material" ? "Unit " : ""}Length (in)`}
                     value={dimensions.length}
                     onChange={handleChange('length')}
                     type="number"
                  />
               </FormControl>
            </Grid>
            <Grid item xs={6}>
               <FormControl fullWidth margin="normal">
                  <TextField
                     label={`${mode === "material" ? "Unit " : ""}Width (in)`}
                     value={dimensions.width}
                     onChange={handleChange('width')}
                     type="number"
                  />
               </FormControl>
            </Grid>
            <Grid item xs={6}>
               <FormControl fullWidth margin="normal">
                  <TextField
                     label={`${mode === "material" ? "Unit " : ""}Height (in)`}
                     value={dimensions.height}
                     onChange={handleChange('height')}
                     type="number"
                  />
               </FormControl>
            </Grid>
            {mode !== "material" && <Grid item xs={6}>
               <FormControl fullWidth margin="normal">
                  <TextField
                     label="Beam Width (ft)"
                     value={dimensions.beamWidth}
                     onChange={handleChange('beamWidth')}
                     type="number"
                  />
               </FormControl>
            </Grid>}
            
            {mode !== "material" && <Grid item xs={6}>
               <FormControl fullWidth margin="normal">
                  <TextField
                     label="Total Weight (lbs)"
                     value={totalWeight}
                     onChange={(e) => setTotalWeight(e.target.value)}
                     type="number"
                  />
               </FormControl>
            </Grid>}
         </Grid>
      </Box>
   );
};

export default DimensionsFields;
