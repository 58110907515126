import React, {useCallback, useState, useEffect, useMemo, useContext} from 'react';
import {useDropzone} from "react-dropzone";
import {makeStyles} from "@mui/styles";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from "@mui/material";
import {Backup, CloudUpload} from "@mui/icons-material";
import {DemoContext} from "../../lib/contexts/DemoContext";
import {base64ToBlob, getVideoFileDuration} from "../../lib/helpers/helpers";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import {getUploadPresign} from "../../lib/api";
import useCurrentUser from "../../lib/hooks/useCurrentUser";
import axios from "axios";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& svg':{
      marginBottom:-6,
      marginRight:4,
    },
    '& input':{
      borderColor: theme.palette.primary.main,
    }
  },
  cancelButton:{
    color: theme.palette.error.main,
  },
  addButton:{
    color: theme.palette.primary.main,
  },
  container:{
    maxHeight:250,
    overflowY: 'auto',
    margin:'20px auto 20px',
    textAlign:'center',
    '& *':{
      textAlign:'center',
    }
  },
  dropzone:{
    maxWidth:'60%',
    margin:'20px auto auto',
    padding:30,
    flex: 1,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#9d9d9d',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
  dropzoneActive:{
    maxWidth:'60%',
    margin:'20px auto auto',
    padding:30,
    flex: 1,
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    color: '#9d9d9d',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  },
}));

const UploadMediaDialog = ({
                             smallButton = true,
                             forceOpen = false,
                             disabled = false,
                             refetch,
                             invisible = false,
                             allowMultiple = true,
                             title = 'Upload Video / Photo',
                             uploadType = 'driver_profile_picture',
                             quoteID = null,
                             orderID = null,
                             entityID = null
}) => {
  const classes = useStyles();
  const {
    actions: { enqueueSnackbar },
  } = useContext(DemoContext);
  const { token, userID} = useCurrentUser();

  const [open, setOpen] = useState(invisible);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [files, setFiles] = useState([]);
  const [thumbnails, setThumbnails] = useState([]);
  const [presignUploadUrls, setPresignUploadUrls] = useState([]);

  const fetchPresignUrls = async (fileDetails) => {
    const urls = await Promise.all(fileDetails.map(async (file) => {
      try {
        const mediaDetails = {
          latitude:null, // todo
          longitude:null,
          format:file.contentType,
          duration:file.duration,
          media_type: uploadType,
        }
        const response = await getUploadPresign(file.fileExtension, file.filename, file.fileSize, uploadType, token, quoteID, orderID, entityID, mediaDetails);
        if (!response || !response.presigned_url) {
          enqueueSnackbar(`Oops! Please retry attaching media.`, {variant:'error'})
          console.error('Invalid response or missing presigned_url', response);
          return { ...file, presignedUrl: null };
        }
        return { ...file, presignedUrl: response.presigned_url }
      } catch (error) {
        enqueueSnackbar(`Oops! Please retry attaching media. Error: ${JSON.stringify(error)}`, {variant:'error'})
        console.error('Error fetching presigned URL:', error);
        return { ...file, presignedUrl: null };
      }
    }));
    setPresignUploadUrls(urls); // Update state with combined data
  };

  // Effect to trigger presign URL fetch when files change
  useEffect(() => {
    if (files.length > 0) {
      const fileDetails = files.map(file => ({
        duration: file.duration,
        fileData: file.fileData,
        fileExtension: file.fileExtension,
        filename: file.filename,
        fileSize: file.fileSize,
        contentType: file.contentType,
      }));
      fetchPresignUrls(fileDetails);
    }
  }, [files]);
  const handleUpload = useCallback(async () => {
    setSaving(true);
    try {
      const responses = await Promise.all(presignUploadUrls.map(file => {
        const base64Data = file.fileData;
        const blobData = base64ToBlob(base64Data, file.contentType); // Convert Base64 to Blob

        const url = presignUploadUrls.find(u => u.filename === file.filename).presignedUrl;
        return axios.put(url, blobData, {
          headers: {
            'Content-Type': file.contentType,
          }
        });
      }));

      const allSuccessful = responses.every(response => response.status === 200);
      if (!allSuccessful) {
        console.error('Error uploading files:', responses);
        enqueueSnackbar('Upload Failed!', { variant: 'error' });
      } else {
        setSaved(true);
        enqueueSnackbar('Upload Received!', { variant: 'success' });
        handleReset();
      }
    } catch (error) {
      console.error('Error during upload:', error);
      enqueueSnackbar('Upload Failed!', { variant: 'error' });
    }
    setSaving(false);
  }, [files, presignUploadUrls]);
  const handleUpload_old = useCallback(async () => {
    setSaving(true);
    try {
      const responses = await Promise.all(presignUploadUrls.map(file => {
        const url = presignUploadUrls.find(u => u.filename === file.filename).presignedUrl;
        return axios.put(url, file.fileData, {
          headers: {
            'Content-Type': file.contentType,
          }
        });
      }));

      const allSuccessful = responses.every(response => response.status === 200);
      if (!allSuccessful) {
        console.error('Error uploading files:', responses);
        enqueueSnackbar('Upload Failed!', { variant: 'error' });
      } else {
        setSaved(true);
        enqueueSnackbar('Upload Received!', { variant: 'success' });
        handleReset();
      }
    } catch (error) {
      console.error('Error during upload:', error);
      enqueueSnackbar('Upload Failed!', { variant: 'error' });
    }
    setSaving(false);
  }, [files, presignUploadUrls]);


  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useS3Upload({
        presignUploadUrls,
    onUploadStart: event => console.log(event),
    onUploadReady: event => console.log(event),
    onError: event => console.log(event),
    getFilesFromEvent: event => myCustomFileGetter(event)
  }, allowMultiple );
  async function myCustomFileGetter(event) {
    const files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    for (var i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);

      Object.defineProperty(file, 'myProp', {
        value: true
      });

      files.push(file);
    }
    console.log(files);
    return files;
  }

  useEffect(() => {
    const handleFiles = async (files) => {
      const fileDetails = [];
      const thumbnailUrls = [];

      for (const file of files) {
        const reader = new FileReader();

        const promise = new Promise((resolve, reject) => {
          reader.onabort = () => {
            console.log('file reading was aborted');
            reject(new Error('File reading was aborted'));
          };
          reader.onerror = () => {
            console.log('file reading has failed');
            reject(new Error('File reading has failed'));
          };
          reader.onload = async () => {
            const binaryStr = reader.result;
            let duration = null;

            // Check if the file is a video and attempt to get the duration
            if (file.type.startsWith('video')) {
              duration = await getVideoFileDuration(file).catch(() => null);
            }

            // Generate a thumbnail for the file
            const thumbReader = new FileReader();
            thumbReader.onload = () => {
              thumbnailUrls.push(thumbReader.result);
              fileDetails.push({
                duration: duration,
                fileData: binaryStr,
                fileExtension: file.name.split('.').pop(),
                filename: file.name,
                fileSize: file.size,
                contentType: file.type,
              });
              resolve();
            };
            thumbReader.readAsDataURL(file);
          };

          if (file.type.startsWith('video')) {
            reader.readAsArrayBuffer(file);
          } else {
            reader.readAsDataURL(file);
          }
        });

        await promise;
      }

      // Update state with all the files and thumbnails
      setFiles(fileDetails);
      setThumbnails(thumbnailUrls);
    };

    if (acceptedFiles.length > 0) {
      handleFiles(acceptedFiles).catch(error => console.error('Error processing files:', error));
    }
  }, [acceptedFiles]);

  useEffect(()=>{
    if(saved){
      if(refetch != undefined){
        refetch();
      }
    }
  },[saved])
  useEffect(()=>{
    setOpen(forceOpen)
  },[forceOpen])
  const handleReset = useCallback(() => {
    setOpen(false);
    setSaving(false);
    setSaved(false);
    setFiles([]);
    setThumbnails([]);
  },[]);
  const handleToggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);
  return (
    <>
      {!invisible && <>
        {smallButton ?
            <IconButton color="primary" component="label" onClick={handleToggleOpen} disabled={open || saving || disabled}>
              <AddAPhotoIcon />
            </IconButton>
        :
            <Button
                fullWidth variant={'contained'} onClick={handleToggleOpen} disabled={open || saving || disabled} style={{display:'block',margin:'10px auto auto'}}>
              <CloudUpload style={{marginTop:-2.5}} />&nbsp;{title ?? 'Upload Photo / Video'}
            </Button>
        }
        </>
      }
      <Dialog
        maxWidth={'sm'}
        fullWidth={true}
        open={open}
        onClose={handleToggleOpen}
        aria-labelledby="upload-video-dialog-form" className={classes.form}
      >
        <DialogTitle id="upload-video-dialog-title"><Backup/>&nbsp;{title}</DialogTitle>
        <DialogContent>
          {
            saving ?
              <CircularProgress style={{margin:'50px auto 50px'}}/>
              :
              <>
                <div {...getRootProps({className: isDragActive ? classes.dropzoneActive : classes.dropzone})}>
                  {thumbnails.map((thumbnail, index) => (
                      <Avatar key={index} src={thumbnail} variant={'square'} style={{ width: '100%', height: 250, margin: '25px auto', display: 'block' }} />
                  ))}
                  <p>Drag &apos;n&apos; drop any relevant media files, or click to browse.</p>
                  <input {...getInputProps()} />
                </div>
              </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleOpen}
                  className={classes.cancelButton}
                  disabled={saving}>
            Cancel
          </Button>
          <Button onClick={handleUpload}
                  className={classes.addButton}
                  disabled={saving || !presignUploadUrls.length}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
const useS3Upload = ({
                       presignedUploadUrl,
                       onUploadStart,
                       onUploadReady,
                       onError,
                     },
                     allowMultiple = false
) => {

  return useDropzone({
    //accept: ['video/*', 'image/*'],
    multiple: allowMultiple,
    //disabled: typeof presignedUploadUrl !== 'string',
  });
}
export default UploadMediaDialog;
