
// Create a theme instance
import {createTheme} from "@mui/material";
import {darken, lighten} from "@mui/material/styles";

export const theme = createTheme({
   themeName: 'Light',
   palette: {
      primary: {
         main: '#2969EF',
         dark: darken('#2969EF', 0.1),
         darker: '#e48c81',
         light: '#9E9EA8',
         text: '#555',
         contrastText: '#fff',
         background: lighten('#2969EF', 0.92),
         backgroundHover: lighten('#2969EF', 0.5),
         border: darken('#2969EF', 0.1),
         bodyText: '#111',
         paperText: '#9E9EA8',
      },
      secondary: {
         main: '#F73741',
         dark: '#651c80',
         light: '#f7d0b9',
         text: '#555',
         contrastText: '#fff',
         background: lighten('#F73741', 0.92),
         backgroundHover: lighten('#F73741', 0.5),
         border: darken('#F73741', 0.1),
         bodyText: '#555',
         paperText: '#EEEEF2',
      },
      tertiary: {
         main: '#001542',
         dark: '#651c80',
         light: '#f7d0b9',
         text: '#555',
         contrastText: '#fff',
         background: lighten('#F73741', 0.92),
         backgroundHover: lighten('#F73741', 0.5),
         border: darken('#F73741', 0.1),
         bodyText: '#555',
         paperText: '#EEEEF2',
      }
   },
   components: {
      MuiAccordion: {
         styleOverrides: {
            root: {
               backgroundColor: 'transparent',
               boxShadow: 'none',
               '&:before': {
                  display: 'none',
               },
               '&.Mui-expanded': {
                  //margin: '0', // Adjust according to your needs
               },
            },
         },
      },
      MuiAccordionSummary: {
         styleOverrides: {
            root: {
               borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
               backgroundColor: 'transparent',
               '&.Mui-expanded': {
                  minHeight: 48,
               },
            },
            content: {
               '&.Mui-expanded': {
                  margin: '12px 0',
               },
            },
         },
      },
      MuiAccordionDetails: {
         styleOverrides: {
            root: {
               padding: '0 16px', // Adjust according to your needs
               backgroundColor: 'transparent',
            },
         },
      },
   },
});

export const accordionStyle = { mt: 2, boxShadow: "none", backgroundColor: "transparent", border: "1px solid rgba(0, 0, 0, 0.23)" }
export const accordionTitleStyle = { width: '100%', fontWeight: 'fontWeightMedium', color: 'rgba(0, 0, 0, 0.6)' }
export const accordionDetailsStyle = { pt:2, pb:2, borderTop: "1px solid rgba(0, 0, 0, 0.12)" }
export const accordionSummaryStyle = {
   backgroundColor: "transparent",
   borderBottom: "1px solid rgba(0, 0, 0, 0.23)",
   '&.Mui-expanded': {
      minHeight: '48px',
   },
   '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
   },
}
