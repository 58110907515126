import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export const ModifyBidDialog = ({
                                  isOpen,
                                  onClose,
                                    orderID,
                                  description,
                                  onConfirm,
                                  onCancel,
                              }) => {
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={isOpen}
            onClose={onClose}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
        >
            <DialogTitle id="confirm-dialog-title">Modify Shipment</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                    #{orderID}
                    <br/>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    Cancel
                </Button>
                <Button onClick={onConfirm} color="primary" autoFocus>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};
