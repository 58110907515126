import React from 'react';
import Header from '../../components/Header';
import BreadCums from '../../components/BreadCums';
import ErrorMessage from './ErrorMessage';
import Apps from '../../components/Apps';
import Footer3 from '../../components/Footer3';

const Error404 = () => {
    return (
        <>
            <Header
                parentMenu='pages'
                headerClass='sc-header-section inner-header-style'
                signUpBtn='inner-btn'
            />
            <BreadCums pageTitle='404 Error' />
            <ErrorMessage />
            <Apps />
            <Footer3 />
        </>
    );
};

export default Error404;
