// MaterialQuoteForm.js
import React from 'react';
import {Stack} from "@mui/material";
import LocationAutocomplete from "../LocationAutocomplete/LocationAutocomplete";
import {useQuote} from "../../lib/contexts/QuoteContext";
import DatesAndRecurringFields from "./DatesAndRecurringFields";
import MaterialTypeAndGradeFields from "./MaterialTypeAndGradeFields";
import DimensionsFields from "./DimensionsFields";
import WeightAndQuantityFields from "./WeightAndQuantityFields";
import AdditionalServiceAndPreferences from "./AdditionalServiceAndPreferences";

const MaterialQuoteForm = () => {
   const {
      pickupLocation, setPickupLocation,
      dropoffLocation, setDropoffLocation
   } = useQuote();
   return (
      <Stack direction="column" spacing={2}>
         <LocationAutocomplete
            label="Pickup Location"
            value={pickupLocation?.formatted_address || ''}
            onPlaceSelected={setPickupLocation}
         />
         <LocationAutocomplete
            label="Dropoff Location"
            value={dropoffLocation?.formatted_address || ''}
            onPlaceSelected={setDropoffLocation}
         />
         <MaterialTypeAndGradeFields/>
         <WeightAndQuantityFields/>
         <DimensionsFields mode={'material'}/>
         <DatesAndRecurringFields/>
         <AdditionalServiceAndPreferences mode={'material'} />
      </Stack>
   );
};

export default MaterialQuoteForm;
