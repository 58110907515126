// OrderList.js
import {useQuote} from "../../lib/contexts/QuoteContext";
import {Box, CircularProgress, Divider, List, Typography} from "@mui/material";
import React from "react";
import QuoteItemDetail from "../QuoteItemDetail/QuoteItemDetail";
import {useTrack} from "../../lib/contexts/TrackContext";

const orders = [
    {
        id: 'quote1',
        transportType: 'Auto',
        pickup: {
            location: '123 PickUp St, Boston, MA',
            date: '2024-04-01',
        },
        dropoff: {
            location: '456 DropOff Ave, Miami, FL',
            date: '2024-04-05',
        },
        vehicle: {
            make: 'Tesla',
            model: 'Model S',
            year: '2023',
        },
        price: 1500,
        specialRequirements: 'Enclosed transport required',
    },
    {
        id: 'quote2',
        transportType: 'Marine',
        pickup: {
            location: 'Port of Los Angeles, CA',
            date: '2024-04-10',
        },
        dropoff: {
            location: 'Port of Seattle, WA',
            date: '2024-04-15',
        },
        vehicle: {
            type: 'Yacht',
            length: '30ft',
        },
        price: 3000,
        specialRequirements: 'No special requirements',
    },
    {
        id: 'quote3',
        transportType: 'Auto',
        pickup: {
            location: '789 PickUp Rd, Austin, TX',
            date: '2024-04-08',
        },
        dropoff: {
            location: '101 DropOff Blvd, Denver, CO',
            date: '2024-04-12',
        },
        vehicle: {
            make: 'Ford',
            model: 'F-150',
            year: '2022',
        },
        price: 800,
        specialRequirements: 'Expedite shipment',
    },
];

const OrderList = ({mode = 'auto'}) => {
    //const { orders, setOrders } = useTrack(); // orders,
    const { isTracking } = useTrack(); // orders,



    if (isTracking) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress />
            </Box>
        );
    }

    if (orders.length === 0) {
        return <Typography variant="h6" align="center">No orders available</Typography>;
    }

    return (
        <List style={{ maxHeight: 400, overflowY: 'auto' }}>
            {orders.map((order) => (
                <React.Fragment key={order.id}>
                    <QuoteItemDetail quote={order} mode={mode} />
                    <Divider variant="inset" component="li" />
                </React.Fragment>
            ))}
        </List>
    );
};

export default OrderList;
