import { useQuery } from 'react-query';
import {getTerms} from "../api";

const useTerms = (params) => {
   const result = useQuery('organization_terms_'+params, () => getTerms(params), {
      refetchOnWindowFocus: false,
      staleTime: 5000,
   });
   return result;
};

export default useTerms;
