import React from 'react';
import { FormControl, TextField, Checkbox, FormGroup, FormControlLabel, Box, MenuItem, Select, InputLabel } from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

const recurringFrequencyOptions = [
   "daily", "weekly", "biweekly", "monthly",
   "bimonthly", "quarterly", "semiannually", "annually"
];

const DatesAndRecurringFields = () => {
   const {
      desiredPickupDate, setDesiredPickupDate,
      desiredDeliveryDate, setDesiredDeliveryDate,
      recurring, setRecurring
   } = useQuote();
   
   const handleRecurringChange = (event) => {
      setRecurring({ ...recurring, [event.target.name]: event.target.value });
   };
   
   return (
      <Box>
         <FormControl fullWidth margin="normal">
            <TextField
               label="Desired Pickup Date"
               type="date"
               value={desiredPickupDate}
               onChange={(e) => setDesiredPickupDate(e.target.value)}
               InputLabelProps={{ shrink: true }}
            />
         </FormControl>
         <FormControl fullWidth margin="normal">
            <TextField
               label="Desired Delivery Date"
               type="date"
               value={desiredDeliveryDate}
               onChange={(e) => setDesiredDeliveryDate(e.target.value)}
               InputLabelProps={{ shrink: true }}
            />
         </FormControl>
         <FormGroup>
            <FormControlLabel
               control={
                  <Checkbox
                     checked={recurring.isRecurring || false}
                     onChange={(e) => setRecurring({ ...recurring, isRecurring: e.target.checked })}
                     name="isRecurring"
                  />
               }
               label="Is Recurring"
            />
            {recurring.isRecurring && (
               <>
                  <FormControl fullWidth margin="normal">
                     <InputLabel>Frequency</InputLabel>
                     <Select
                        value={recurring.frequency || ''}
                        onChange={handleRecurringChange}
                        label="Frequency"
                        name="frequency"
                     >
                        {recurringFrequencyOptions.map((option) => (
                           <MenuItem key={option} value={option}>
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        label="Start Date"
                        type="date"
                        value={recurring.start_date || ''}
                        onChange={handleRecurringChange}
                        name="start_date"
                        InputLabelProps={{ shrink: true }}
                     />
                  </FormControl>
                  <FormControl fullWidth margin="normal">
                     <TextField
                        label="End Date"
                        type="date"
                        value={recurring.end_date || ''}
                        onChange={handleRecurringChange}
                        name="end_date"
                        InputLabelProps={{ shrink: true }}
                     />
                  </FormControl>
               </>
            )}
         </FormGroup>
      </Box>
   );
};

export default DatesAndRecurringFields;
