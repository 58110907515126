import React from 'react';
import {FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Box} from '@mui/material';
import {useQuote} from "../../lib/contexts/QuoteContext";

const SpecialHandlingOptions = ({ mode }) => {
   const { specialHandling, setSpecialHandling, hazardousMaterials, setHazardousMaterials } = useQuote();
   
   const specialHandlingOptions = [
      { label: 'Fragile', value: 'fragile' },
      { label: 'Keep Upright', value: 'keepUpright' },
      { label: 'Live Animals', value: 'liveAnimals' },
      { label: 'Perishable', value: 'perishable' },
      { label: 'Oversized', value: 'oversized' },
      { label: 'Secure Transport', value: 'secure transport' },
      { label: 'High Value', value: 'high value' }
   ];
   
   const handleChange = (event) => {
      const { value, checked } = event.target;
      const newSpecialHandling = checked
         ? [...specialHandling, value]
         : specialHandling.filter(option => option !== value);
      setSpecialHandling(newSpecialHandling);
   };
   
   const handleHazardousMaterialsChange = (event) => {
      setHazardousMaterials(event.target.checked);
   };
   
   return (
      <FormControl component="fieldset" fullWidth>
         <FormLabel component="legend">Special Handling Options</FormLabel>
         <Box sx={{
            maxHeight: '200px',
            overflowY: 'auto'
         }}>
            {mode === "material" && (
               <FormControlLabel
                  control={<Checkbox checked={hazardousMaterials} onChange={handleHazardousMaterialsChange} />}
                  label="Contains Hazardous Materials"
               />
            )}
            {specialHandlingOptions.map(option => (
               <FormControlLabel
                  key={option.value}
                  control={<Checkbox checked={specialHandling.includes(option.value)} onChange={handleChange} value={option.value} />}
                  label={option.label}
               />
            ))}
         </Box>
      </FormControl>
   );
};

export default SpecialHandlingOptions;
