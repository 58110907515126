// RouteInfo.js
import React, {useMemo, useState} from 'react';
import {
    Tooltip,
    Typography,
    ListItem,
    ListItemText,
    Card,
    CardContent,
    List,
    ListItemIcon,
    IconButton,
    Button,
    Grid, Chip
} from '@mui/material';
import {CalendarToday, DirectionsBoat, DriveEta, Info, Layers, LocalShipping, Place, AssignmentInd, ContentCopy, Numbers} from "@mui/icons-material";

import useSummary from "../../lib/hooks/useSummary";
import RefreshButton from "../RefreshButton/RefreshButton";
import {DriverCheckinDialog} from "../DriverCheckinDialog/DriverCheckinDialog";
import {prettyDateTime, toCapitalizeFromUnderScore} from "../../lib/helpers/helpers";

const renderDetailsByType = () => {
    switch (shipment?.type) {
        case 'auto':
            return (
                <>
                    <Typography variant="body2">
                        Vehicle: {shipment.details.vehicle.make} {shipment.details.vehicle.model} ({shipment.details.vehicle.year})
                    </Typography>
                </>
            );
        case 'material':
            return (
                <>
                    <Typography variant="body2">
                        Material Type: {shipment.details.materialType}
                    </Typography>
                    <Typography variant="body2">
                        Total Weight: {shipment.details.totalWeight} lbs
                    </Typography>
                </>
            );
        case 'marine':
            return (
                <>
                    <Typography variant="body2">
                        Vessel Type: {shipment.details.vesselType}
                    </Typography>
                    {shipment.details.vesselName && (
                        <Typography variant="body2">
                            Vessel Name: {shipment.details.vesselName}
                        </Typography>
                    )}
                </>
            );
        default:
            return null;
    }
};

const RouteInfoExtended = ({ mode, overRideQuoteOrder = null, showTitle = true}) => {
    const { data, isLoading, isRefetching, refetch } = useSummary()
    const shipment = useMemo(() => overRideQuoteOrder || data?.route_info, [data, overRideQuoteOrder])
    const [openCheckin, setOpenCheckin] = useState(false)
    const pickupAddress = useMemo(() => {
        return shipment.locations?.find(location => location.type === 'pickup')?.address || shipment?.pickup?.location?.address;
    }, [shipment.locations]);

    const dropoffAddress = useMemo(() => {
        return shipment.locations?.find(location => location.type === 'dropoff')?.address || shipment?.dropoff?.location?.address;
    }, [shipment.locations]);
    const handleCopyPaste = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Content copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };
    const getIconForType = (type) => {
        switch (type) {
            case 'auto':
                return <DriveEta />;
            case 'material':
                return <Layers />;
            case 'marine':
                return <DirectionsBoat />;
            default:
                return <Info />;
        }
    };

    // Function to dynamically return the details based on the shipment type
    const getDetailsForVehicles = (shipment) => {
        switch (shipment?.quote_type || shipment?.type) {
            case 'shipment':
            case 'auto':
                // First, check if shipment.vehicle_make_model is available
                return (
                    <List dense style={{ maxHeight: '200px', overflow: 'auto' }}>
                        {shipment.vehicles && shipment.vehicles.map((vehicle, index) => (
                            <div key={index}>
                                <span>{`${vehicle.year} ${vehicle.make} ${vehicle.model}`}</span>
                                <IconButton size="small" onClick={() => handleCopyPaste(`${vehicle.year} ${vehicle.make} ${vehicle.model}`)}>
                                    <ContentCopy fontSize="small" />
                                </IconButton><br/>

                                <span>{vehicle.vin}</span>
                                <IconButton size="small" onClick={() => handleCopyPaste(vehicle.vin)}>
                                    <ContentCopy fontSize="small" />
                                </IconButton><br/>
                            </div>
                        ))}
                    </List>
                )
                // Then check for detailed vehicle information
             /*   return shipment?.details?.vehicle?.make && shipment?.details?.vehicle?.model && shipment?.details?.vehicle?.year
                    ? `${shipment.details.vehicle.make} ${shipment.details.vehicle.model} (${shipment.details.vehicle.year})`
                    : "Auto";*/
            case 'material':
                return `${shipment?.details?.materialType}, Total Weight: ${shipment?.details?.totalWeight} lbs`;
            case 'marine':
                return `Vessel Type: ${shipment?.details?.vesselType}, Name: ${shipment?.details?.vesselName}`;
            default:
                return 'N/A';
        }
    };
    const getDetailsForType = (shipment) => {
        switch (shipment?.quote_type || shipment?.type) {
            case 'shipment':
            case 'auto':
                return "Auto";
            case 'material':
                return "Material";
            case 'marine':
                return "Marine";
            default:
                return (shipment?.quote_type || shipment?.type) || 'N/A';
        }
    };
    const statusColorMapping = {
        pending: 'default', // Gray
        confirmed: 'primary', // Blue
        in_transit: 'success', // Light Blue
        delayed: 'warning', // Yellow
        delivered: 'success', // Green
        cancelled: 'error' // Red
    };

    const getStatusColor = () => {
        return statusColorMapping[shipment.status] || 'default';
    }
    return (
        <Card style={{marginTop: 30}}>
            <CardContent>
                {showTitle && <Typography variant="h6" gutterBottom>
                        <RefreshButton onRefresh={refetch} isLoading={isLoading || isRefetching}/>

                        <Chip label={toCapitalizeFromUnderScore(shipment?.quote?.status || shipment.status)} color={getStatusColor()} size={'small'}/>
                        <Button
                            color={'secondary'}
                            style={{marginLeft:20}}
                            size={'small'}
                            variant="outlined"
                            onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(pickupAddress)}&destination=${encodeURIComponent(dropoffAddress)}&travelmode=driving`, '_blank')}
                        >View Route</Button>
{/*
                    <Chip label={`# Order ID ${shipment?.id}`} color={getStatusColor()} size={'small'}/>
*/}



                </Typography>}
                {mode === 'driver' && <><List>
                    <ListItem>
                        <Button startIcon={<Place/>} variant="outlined" fullWidth={true} onClick={() => setOpenCheckin(true)}>
                            Check-In
                        </Button>
                    </ListItem>
                </List>
                    {mode === 'driver' && <DriverCheckinDialog isOpen={openCheckin}
                                          onClose={() => setOpenCheckin(false)}
                    />}
                </>}
                <Grid container item xs={12} >
                <Grid item xs={4}>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LocalShipping />
                            </ListItemIcon>
                            <ListItemText primary={"Type: " + getDetailsForType(shipment) || ""} secondary={getDetailsForVehicles(shipment)} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentInd/>
                            </ListItemIcon>
                            <ListItemText
                                primary="Driver"
                                secondary={shipment.driver[0] ? `${shipment.driver[0].name} (${shipment.driver[0].carrier.name})` : 'No Driver Assigned'}
                            />

                        </ListItem>
                        {shipment?.expectedDelivery && <ListItem>
                            <ListItemIcon>
                                <CalendarToday/>
                            </ListItemIcon>
                            <ListItemText primary="Expected Delivery"
                                          secondary={prettyDateTime(shipment?.expectedDelivery)}/>
                        </ListItem>}
                    </List>
                </Grid>
                <Grid item xs={4}>
                    <List>

                        <ListItem>
                            <ListItemIcon>
                                <Place />
                            </ListItemIcon>
                            <ListItemText primary="Pick-up Location"
                                          secondary={<>
                                              {`${pickupAddress}`}
                                              <br/>
                                              {`${prettyDateTime(shipment.actual_pickup_date_time || shipment?.quote?.desired_pickup_datetime || shipment?.pickup?.date)}`}
                                          </>}/>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Place />
                            </ListItemIcon>
                            <ListItemText primary="Drop-off Location" secondary={<>
                            {`${dropoffAddress}`}
                                <br/>
                            {`${prettyDateTime(shipment.actual_delivery_date_time || shipment.estimated_delivery_time || shipment.quote?.desired_delivery_datetime || shipment.dropoff?.date)}`}
                            </>} />
                        </ListItem>
                        {shipment?.additionalInfo && (
                            <ListItem>
                                <ListItemIcon>
                                    <Info />
                                </ListItemIcon>
                                <ListItemText primary="Additional Information"
                                              secondary={
                                                  <ul>
                                                      {shipment?.additionalInfo?.map((info, index) => (
                                                          <li key={index}>{info}</li>
                                                      ))}
                                                  </ul>
                                              } />
                            </ListItem>
                        )}
                    </List>
                </Grid>
                <Grid item xs={4}>
                    <List>

                        <ListItem>
                            <ListItemIcon>
                                <Numbers />
                            </ListItemIcon>
                            <ListItemText primary={"Order ID"} secondary={shipment?.id} />
                        </ListItem>
                        {shipment?.quote.additional_services && (
                            <ListItem>
                                <ListItemIcon>
                                    <Info />
                                </ListItemIcon>
                                <ListItemText primary="Additional Services"
                                              secondary={
                                                  <ul>
                                                      {shipment?.quote.additional_services?.map((info, index) => (
                                                          <li key={index}>{info}</li>
                                                      ))}
                                                  </ul>
                                              } />
                            </ListItem>
                        )}
                    </List>
                </Grid>

                    <Typography variant={'caption'}>
                        Created: {prettyDateTime(shipment.created_at)} - Order updated: {prettyDateTime(shipment.updated_at)}
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default RouteInfoExtended;
